import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import sendMessageThroughChannels,{ getLoginToken, TruncateWithTooltip } from "../../../components/src/Utils.web";
import { v4 as uuidv4 } from 'uuid';
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
export interface UserInfo{
  id: number,
  name: string,
  role: string,
  message: string,
  time: string,
  image: string,
  count: number
}
export interface ResponseObj{
  message?:string
  chat?:ChatRespObj
  data?:ChatObj | Array<ChatObj> | Array<AccountDetailsItemObj>
  errors?:Array<TokenObject>
  error?:string
  flag_reasons?: UserReasons[]
}
export interface TokenObject{
  token:string
}
export interface MessageObj{
    id: string,
    type: string,
    attributes: {
      id: number,
      message: string,
      account_id: number,
      chat_id: number,
      updated_at: string,
      is_mark_read: boolean,
      created_at: string,
      created_by: {
        account_id: number,
        full_name: string,
        profile_image: null 
      },
      attachments:  Array<AttachmentObj> |  null
    } 
}
export interface AttachmentObj{
id:number,
url:string
}
interface AccountDetailsObj{
  id: number,
  email: string,
  activated: boolean,
  full_phone_number: string,
  type: string,
  role: string
}

interface ProfileDetailsObj {
  data: {
    id: string,
    type: string,
    attributes: {
      id: number,
      full_name: string,
      location: null,
      current_title: null,
      full_phone_number: null,
      email: string,
      experience: null,
      currency: null,
      salary: null,
      future_self: null,
      current_company: null,
      company_indusry: null,
      bio: null,
      work_email_id: null,
      personal_email_id: null,
      city: null,
      country: null,
      skills: Array<Array<string>>,
      selected_group_context: string,
      open_for_direct_approach: boolean,
      potential_score: null,
      relevance_score: null,
      overall_rank: null,
      company_name: null,
      company_website: null,
      company_size: null,
      company_founded_year: null,
      added_to_pipeline: boolean,
      pipeline_id: null,
      photo: string,
      total_connections: number,
      mutual_connections: null,
      is_followed: boolean,
      connection_request_details: null,
      is_reported: boolean,
      account_details: AccountDetailsObj,
      work_experiences: {
        data: []
      },
      resume: null,
      cover_letter: null
    }
  }
}

export interface  AccountDetailsItemObj
{
  id: string,
  type: string,
  attributes: {
    activated: boolean,
    country_code: null,
    email: string,
    type:string,
    created_at: string,
    updated_at: string,
    device_id: null,
    unique_auth_id: string,
    phone_number: null,
    role: string,
    profile_details: ProfileDetailsObj,
    connection_request_details: null | {
      id: number;
      sender_id: number;
      receiver_id: number;
      status: "accepted" | "pending" | string;
      created_at: string;
      updated_at: string;
      note: string;
    }
    
  }
}
export interface ChatObj{
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      chat_type: string,
      created_at: string,
      current_user_id: number,
      starred: boolean,
      account_details: {
        data: Array<AccountDetailsItemObj> 
      },
      messages: Array<MessageObj>,
      unread_messages_count?:number,
      last_message?:LastMessageObj,
      is_reported: boolean
    }
}
interface LastMessageObj {
  id: string,
  type: string,
  attributes: {
    id: number,
    message: string,
    account_id: number,
    chat_id: number,
    updated_at: string,
    is_mark_read: boolean,
    created_at: string,
    created_by: CreatedBy,
    attachments: null
  }
}
interface CreatedBy {
  account_id: number,
  full_name: string,
  profile_image: null
}
interface ChatRespObj{
data:ChatObj
}

interface ChatDetailsObj{
 chat:ChatRespObj
  }

export interface UserReasons {
  id: number,
  reason: string,
  created_at: string,
  updated_at: string
  }


// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  isSubscribed: boolean;
  chatLimit: number;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  anchorEl: HTMLElement | null;
  anchorEll: HTMLElement | null;
  anchorElll: HTMLElement | null;
  anchorEllll: HTMLElement | null;
  value: string,
  values: string,
  reportClick: boolean,
  openReason: boolean,
  selectedReason: {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  },
  userReasons: UserReasons[],
  suggestions: Array<{ one:string }>,
  suggestion:Object
  hovered: boolean,
  hoveredBorder: boolean,
  showSuggestions: boolean,
  hoveredItemId: number | null
  selectedVisible: boolean, 
  manageConversationClicked: boolean,
  dialogOpen: boolean,
  checkedItems: number[],
  viewThreeDotsStatus: boolean,
  viewChatThreeDotsStatus: boolean,
  sendButtonPopupStatus: boolean
  searchReceptient: string,
  searchPopUpStatus: boolean,
  deleteMultiple: boolean,
  newMessageFlow:boolean,
  searchnewMessageReceptient:string,
  searchNewMessagePopUpStatus: boolean,
  newMessageSearchFlow:boolean,
  imageModalOpen:boolean,
  selectedFiles:Array<File>,
  selectedImage:string | null,
  searchMsgReceptient: string,
  searchMsgPopUpStatus: boolean,
  isNewMsgMessage:boolean,
  hoveredMsgItemId: null | number,
  isConversationStartWithExistUser:boolean,
  isUserSelectedInMsgFlow:boolean,
  maxRows:number,
  defaultMsgMessageflow:boolean,
  newUsersList:Array<AccountDetailsItemObj>
  existUsersList:Array<ChatObj>,
  createdChatName:string,
  isStared:boolean,
  messageText:string,
  hoveredMsgExistUserItemId: null | number,
  acceptanceCriteria:string,
  toggleMessageBoxOpenClose:boolean,
  massgeMaxRows:number,
  viewThreeDotsFlotMessageStatus:boolean,
  selectedUser:AccountDetailsItemObj | null,
  isConversationStartWithNewUser:boolean,
  selectedNewUserInfo:ProfileDetailsObj | null,
  chatId:string,
  starUnstarChat:string,
  currentUserId:string,
  existSearchUsersList:Array<ChatObj>,
  messageListInfo:ChatDetailsObj | null, 
  lastMessageTime:string | null
  previewImageUrl:string,
  previousMessageAccountId:string,
  send_message_option:string,
  newChat: string,
 redirectUserInfo:ProfileDetailsObj | null,
  reportedSuccessfully: boolean,
  loaderMessage:boolean,
  progressMessage:number,
  remainingTimeMessage:number,
  hideList: boolean,
  userRole: string,
  loader:boolean,
  isSubscribedToAbly:boolean,
  isUnread: string,
  newSearchLoader: boolean,
  callListApiFlag: boolean
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  getSubscriptionApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement & { click: Function }>;
  getAllNewUsersApiCallId: string = "";
  sendMessageApiCallId: string = "";
  getChatDetailsApiCallId: string = "";
  deleteChatApiCallId: string = "";
  getFousedUsersApiCallId: string = "";
  createChatApiCallId: string = "";
  markChatMessagesUnreadApiCallId: string = "";
  starUnstarChatApiCallId: string = "";
  getUserProfileApiId: string  = "";
  sendMessageOptionSetApiCallId:string  = "";
  addReasonsApiCallId: string = "";
  reasonApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      callListApiFlag: false,
      token: "",
      isSubscribed: true,
      chatLimit: configJSON.maxChatLimit,
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      anchorEl: null,
      anchorEll: null,
      anchorElll: null,
      anchorEllll: null,
      value:'',
      values: '',
      reportClick: false,
      openReason: false,
      userReasons: [{
          id: 1,
          reason: "",
          created_at: "",
          updated_at: ""
      }],
      selectedReason: {
          id: 0,
          reason: "",
          created_at: "",
          updated_at: "",
          otherReason: ""
      },
      suggestions: [{ one: 'Suggestion 1' }, { one: 'Suggestion 2' }, { one: 'Suggestion 3' }, { one: 'Suggestion 4' },],
      suggestion:{ one: 'Suggestion 1' },
      hovered: false,
      hoveredBorder: false,
      hoveredItemId: null,
      selectedVisible: false,
      manageConversationClicked: false,
      dialogOpen: false,
      checkedItems: [],
      showSuggestions: false,
      viewThreeDotsStatus: false,
      viewChatThreeDotsStatus: false,
      sendButtonPopupStatus: false,
      searchReceptient: "",
      searchPopUpStatus: false,
      deleteMultiple: false,
      newMessageFlow:false,
      searchnewMessageReceptient:'',
      searchNewMessagePopUpStatus:false,
      newMessageSearchFlow:false,
      imageModalOpen:false,
      selectedFiles:[],
      selectedImage:null,
      searchMsgReceptient:"",
      searchMsgPopUpStatus:false,
      isNewMsgMessage:false,
      hoveredMsgItemId:null,
      isConversationStartWithExistUser:false,
      isUserSelectedInMsgFlow:false,
      maxRows:2,
      defaultMsgMessageflow:false,
      newUsersList:[],
      existUsersList:[],
      createdChatName:'',
      isStared:false,
      messageText:'',
      hoveredMsgExistUserItemId:null,
      acceptanceCriteria:'.gif,.jpg,.png',
      toggleMessageBoxOpenClose:false,
      massgeMaxRows:4,
      viewThreeDotsFlotMessageStatus:false,
      selectedUser: null,
      isConversationStartWithNewUser:false,
      selectedNewUserInfo:null,
      chatId:'',
      starUnstarChat:'',
      currentUserId:'',
      existSearchUsersList:[],
      messageListInfo:null,
      lastMessageTime:null,
      previewImageUrl:'',
      previousMessageAccountId:'',
      send_message_option:'',
      newChat: "",
      redirectUserInfo:null,
      reportedSuccessfully: false,
      loaderMessage:false,
      progressMessage:0,
      remainingTimeMessage:0,
      hideList: false,
      userRole: "",
      loader:false,
      isSubscribedToAbly:false,
      isUnread: "",
      newSearchLoader: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getFocusedUserList('')
    this.checkForSubscription()
    this.getUserProfile()

    const userRole = await getStorageData("userRole")
    this.setState({
      userRole
    })
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {

    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleSubscribeNavigation = () => {
      const msg = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
      msg.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
      this.send(msg);
  }

  showSubscribe = () => {
    return (!this.state.isSubscribed && !this.state.newMessageFlow)
  }

  checkForRecruiterMessage = () => {
    let isRecruiterMessage = false
    this.state.existUsersList.forEach((chat: ChatObj) => {
      const userDetails = chat.attributes.account_details.data.find((user) => chat.attributes.current_user_id != Number(user.id))

      if (userDetails) {
        if (userDetails.attributes.profile_details?.data.attributes.account_details.role === "recruiter") {
          isRecruiterMessage = true
          return
        }
      }
    })
    return isRecruiterMessage
  }

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message)
    }

    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      this.handleRedirectUserResponse(message)
    }
   
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id 
    ) {
      if (
        responseJson?.data && apiRequestCallId === this.getChatListApiCallId
      ) {
        this.getChatListApiCallId = "";
        const chatList = JSON.parse(JSON.stringify(responseJson.data));
        this.handleChatListResponse(chatList)
      }
      if (apiRequestCallId === this.createChatRoomApiCallId &&
        responseJson
      ) {
        this.createChatRoomApiCallId = "";
        this.hideModal();
        this.getChatList(this.state.token);
      }
      if (apiRequestCallId === this.getSubscriptionApiCallId && responseJson) {
        this.handleSubscriptionResponse(responseJson)
      }

    }
   
   this.handleApiResponse(responseJson,apiRequestCallId)
  }

  handleConditionNumber = (condition: boolean, truePart: number, falsePart: number) => {
    return condition ? truePart : falsePart
  }

  handleRedirectUserResponse=(message:Message)=>{
    const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
    if (sessionData) {
      if (sessionData.from == "Profile") {
        this.setState({ redirectUserInfo: sessionData.accountId })
      }
    }
  }

  handleApiResponse=(responseJson:ResponseObj,apiRequestCallId:string)=>{
    if(responseJson && (responseJson.data || responseJson.chat)){
      this.handleResponseWithData(apiRequestCallId,responseJson)
    }
    if(apiRequestCallId === this.deleteChatApiCallId){
      this.handleDeleteAPIResp(responseJson)  
    }
    if(apiRequestCallId === this.reasonApiCallId){
      this.handleReasonAPIResp(responseJson)  
    }
    if(apiRequestCallId === this.addReasonsApiCallId){
      this.handleAddReasonAPIResp(responseJson)  
    }
    
    if(responseJson && !responseJson.errors){
      this.handleStarUnstarMarkAsUnreadResponse(apiRequestCallId)
    }
    if(responseJson && responseJson.errors && this.handleErrorsToAPI(apiRequestCallId)){
  this.handleTokenError(responseJson,apiRequestCallId)
    }

    if (responseJson && responseJson.error && this.handleErrorsToAPI(apiRequestCallId)) {
      this.handleError(responseJson, apiRequestCallId)
    }

    if (responseJson && responseJson.message && this.handleErrorsToAPI(apiRequestCallId)) {
      this.handleMessageError(responseJson, apiRequestCallId)
    }
        
    

  }



  handleChangeRadio = (event:  {target:{value:string}}) => {
    this.setState({send_message_option: event.target.value },()=>{
      this.setSendMessageOption()
    });
   

  };

  handleHiddenList = () => {
    this.setState({
      hideList: !this.state.hideList
    })
  }


  handleMouseEnter = (itemId: number) => {
    this.setState({ hoveredItemId: itemId });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredItemId: null });
  };


  handleToggleSelectedVisibility = (event:{stopPropagation:()=>void}) => {
    if(event){
      event.stopPropagation()
    }
    this.setState((prevState) => ({
      viewThreeDotsStatus:false,
      selectedVisible: true,
      manageConversationClicked: true,
      deleteMultiple: true,

    }));
  };
  handleToggleNewMessageFlow=()=>{
    if(this.state.existUsersList.length > this.state.chatLimit) { return toast.error(configJSON.limitError)}
    if(this.state.isSubscribed) {
    this.setState((prevState) => ({
      newMessageFlow: true,
      isConversationStartWithNewUser: false,
      searchnewMessageReceptient: '',
      selectedFiles: [],
      chatId: '',
      messageText: '',
      hideList: true
    }));
    this.messageToggleDown()
  }
  }
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleInitiateDeleteMultipleConversation = () => {
    this.setState({ dialogOpen: false,checkedItems:[], viewThreeDotsStatus:false,selectedVisible:false,manageConversationClicked:false,deleteMultiple:false}, () => {
    });
  };

  handleDeleteConfirm = () => {
    this.setState({ dialogOpen: false, deleteMultiple: false, selectedVisible: false, manageConversationClicked: false,searchReceptient:'' });
    this.deleteChat()
  };


  handleCheckboxChange = (userId: number) => {
    this.setState((prevState) => {
      const checkedItems = prevState.checkedItems.includes(userId)
        ? prevState.checkedItems.filter((itemId) => itemId !== userId)
        : [...prevState.checkedItems, userId];
      return { checkedItems };
    });
  };

  openViewThreeDots = () => {
    if(this.state.isSubscribed) {
      this.setState({ 
        viewThreeDotsStatus: true 
      })
    }
  }
  closeViewThreeDots = () => {
    this.setState({
      viewThreeDotsStatus: false, viewChatThreeDotsStatus: false,
      sendButtonPopupStatus: false,
      viewThreeDotsFlotMessageStatus:false,
      searchPopUpStatus: false
    })
  }
  openViewChatThreeDots = () => {
    this.setState({ 
      viewChatThreeDotsStatus: true,
      deleteMultiple:false 
    })
  }
  handleSendButtonPopup = () => {
    this.setState({ 
      sendButtonPopupStatus: true 
    })
  }
  handelSearch = (event: {target:{value:string}}) => {
    const searchValue = event.target.value;
    this.setState({
      searchReceptient: searchValue,
      searchPopUpStatus: searchValue !== ''
    });
    if(this.state.chatLimit < configJSON.maxChatLimit) {
      const filteredList= this.state.existUsersList.filter((item, index) => !this.checkForBlur(index))
      this.setState({ existSearchUsersList: filteredList })
    }
    else{
      this.getFocusedUserList(searchValue)
    }
  }

  handelNewMessageSearch= (event: {target:{value:string}}) => {
    const searchValue = event.target.value;
    this.setState({
      searchnewMessageReceptient: searchValue,
      searchNewMessagePopUpStatus: searchValue !== ''
    });
    this.getAllUserList(searchValue)
  }
  startConversastion=(userType:string, item:AccountDetailsItemObj,chatInfo:ChatObj)=>{
    this.setState({
      newMessageFlow: false,
      accountId:Number(item.id),
      searchPopUpStatus:false,
      selectedUser:item,
      chatId:chatInfo.id,
      createdChatName:chatInfo.attributes.name,
      isStared:chatInfo.attributes.starred,
      selectedFiles:[],
      hideList: true,
      existUsersList: this.state.existUsersList.filter(user => chatInfo.id === user.id)
    });
    if(chatInfo.attributes && chatInfo.attributes.last_message && chatInfo.attributes.last_message.attributes.created_at){
      this.setState({
        lastMessageTime:chatInfo?.attributes?.last_message?.attributes.created_at,
      });
    }
    this.messageToggleDown()
    this.getChatDetails(chatInfo.id, true)
    if(chatInfo.attributes.is_reported) {
      toast.error("This conversation has been reported")
    }
  }

  startConversastionWithNewUser=(userType:string,userInfo:AccountDetailsItemObj)=>{
    let existingChat: ChatObj | undefined
    if (userInfo.attributes.connection_request_details?.status !== "accepted" && !userInfo.attributes.profile_details.data.attributes.open_for_direct_approach) {
      toast.info("You can't send message outside of your network", {autoClose: false, onClose: () => {
        const message: Message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), 'Profile');
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), userInfo.id);
        this.send(message);
      }});
      return;
    }

    this.state.existUsersList.filter((chat) => chat.attributes.is_reported).forEach((chat: ChatObj) => {
      const userDetails = chat.attributes.account_details.data.find((user) => chat.attributes.current_user_id != Number(user.id))

      if (userDetails) {
        if (userDetails.attributes.profile_details?.data.attributes.account_details.id === Number(userInfo.id)) {
          existingChat = chat
          return
        }
      }
    })

    if (existingChat) {
      this.setState({
        chatId: existingChat.id,
        searchnewMessageReceptient: '',
        searchNewMessagePopUpStatus: false,
      })
      this.selectUser(userInfo, existingChat)
      toast.error("This conversation has been reported")
      return
    }
    this.setState({
      newMessageFlow: true,
      searchnewMessageReceptient:'',
      searchNewMessagePopUpStatus:false,
      isConversationStartWithNewUser:true,
      selectedNewUserInfo:userInfo.attributes.profile_details,
      selectedFiles:[],
      newChat: userInfo.id
    });
    this.messageToggleDown()
  }
  handleImageModalClose=()=>{
    this.setState({imageModalOpen:false})
  }
  handleOpenImageModal=(imageUrl:string)=>{
    this.setState({imageModalOpen:true,previewImageUrl:imageUrl})
  }
  handleFileSelect=(event: React.ChangeEvent<HTMLInputElement>)=>{
    if(event.target.files){
      const parts = (event.target.files[0].name).split('.');
      if(parts[1] && ![ "gif","jpg","png","jpeg","docx","doc","pdf"].includes(parts[1].toLowerCase())){
        toast.error("File type not supported", { toastId: 'apiError'})
        return 
      }
      const newFiles = Array.from(event.target.files);
    let fileslist=[...this.state.selectedFiles, ...newFiles]
    if(fileslist.length>3){
      toast.error("You have reached the maximum file limit", { toastId: 'apiError'})
      return 
    }
      this.setState({selectedFiles:fileslist})
      if(fileslist.length<=2){
        let height=fileslist.length*67  // 73
        this.setBoxHeights(height)
      }
    }
  this.scrollToLastFileUpload()
  }
    scrollToLastFileUpload=()=>{
    let element=document.getElementById("uploadfileboxList")
    if(element){
      const toplast = element.lastElementChild;
      if(toplast){
        setTimeout(() => {
          toplast.scrollIntoView();
        }, 500);
      }
    }
  }

  setBoxHeights=(height:number)=>{

    const messageBox=document.getElementById('messageBoxexistUser');
    if(messageBox){
      messageBox.style.height=window.screen.height > 850 ? `calc(-512px - ${height}px + 100vh)` :  `calc(-425px - ${height}px + 100vh)`      
    }

    const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
    if(newMessageBox){
      newMessageBox.style.height=`calc(-393px - ${height}px  + 100vh)` 
    }
    const emptyBox= document.getElementById('emptyBox'); 
    if(emptyBox){
      emptyBox.style.height=`calc(100vh - 453px - ${height}px)`
    }
  
   
  }

  handleStared=(actionType:string)=>{
    this.setState((prevState) => ({
      starUnstarChat:actionType
    }),()=>
    this.starTheChat()
    );
   
  }
  handleChangeMessage=(event:{target:{value:string}})=>{

   this.setState({messageText:event.target.value})
  }
  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>)=>{
    const target = event.target as HTMLInputElement;
    this.setState({messageText:target.value},async ()=>{
      let checksendbtnStatus=this.disbableSendButton()
      if (event.key === 'Enter' && this.state.send_message_option=="Press Enter to Send" && !checksendbtnStatus) {
        event.preventDefault();
        if(target.value.trim()!='' || this.state.selectedFiles.length>0) {
          this.checkForNewUser()
        }
      
    }
  })
   }

  handleExistUserMouseEnter = (itemId: number) => {
    this.setState({ hoveredMsgExistUserItemId: itemId });
  };
  handleExistUserMouseLeave = () => {
    this.setState({ hoveredMsgExistUserItemId: null });
  };

  openFile=(fileType:string)=>{
    this.setState({acceptanceCriteria:fileType=='image'?'.gif,.jpg,.png,.jpeg':'.docx,.doc,.pdf'},()=>{
      this.fileInputRef.current?.click()
    })

  }

  getAllUserList = (searchValue:string) => {
    if(searchValue.length === 0) {
      return
    }
    this.setState({
      newSearchLoader: true,
      newUsersList: []
    })
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNewUsersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllNewUsers}?search_query=${searchValue}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  checkForBlur = (index: number) => {
    return (index + 1) > this.state.chatLimit 
  }

  handleCondition = (condition: boolean, truePart: string, falsePart: string) => {
    return condition ? truePart : falsePart
  }

  handleConditionElement = (condition: boolean, truePart: React.ReactElement, falsePart: React.ReactElement) => {
    return condition ? truePart : falsePart
  }
 
  checkForNewUser = () => {
    let existingUsers: AccountDetailsItemObj[] = []
    let chatId = ""
    this.state.existUsersList.forEach((item: ChatObj) => {
      const userInfo = item.attributes.account_details.data.find((selectItem: AccountDetailsItemObj) => item.attributes.current_user_id != Number(selectItem.id))
      if (userInfo) {
        existingUsers.push(userInfo)
        if (this.state.selectedNewUserInfo?.data.attributes.account_details.id === Number(userInfo.id)) {
          chatId = item.id
          this.setState({
            chatId: chatId
          })
        }
      }

    })
    if (this.state.selectedNewUserInfo?.data.attributes.account_details.id && (!existingUsers.some((user) => Number(user.id) === this.state.selectedNewUserInfo?.data.attributes.account_details.id))) {
      this.createChat(this.state.selectedNewUserInfo?.data.attributes.account_details.id.toString())
    } else {
      this.sendMessageToSelectedUser(chatId)
    }
  }
 callGetFetchUserList=(message:any)=>{
    this.getFocusedUserList('')
  }
  
  sendMessageToSelectedUser = (chatId?: string) => {
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
 
   let formData = new FormData();
   if(this.state.messageText){
    formData.append("message[message]", this.state.messageText);
   }
   else{
    formData.append("message[message]",'');
   }

   if(this.state.selectedFiles.length>0){
    this.setState({ loaderMessage: true })
    this.state.selectedFiles.forEach((file:File)=>{
      formData.append("message[attachments][]", file);
    })
   
   }
   const startTime = Date.now();

   const config: AxiosRequestConfig = {
    headers: { token: token ?? '' },
    onUploadProgress: (progressEvent: AxiosProgressEvent) => 
    {
      
      const { loaded, total } = progressEvent;

      if (total) {
        const percentCompleted = Math.round((loaded * 100) / total);
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) / 1000;

        const uploadSpeed = loaded / elapsedTime;
        const remainingTime = (total - loaded) / uploadSpeed;

        this.setState({ 
          progressMessage: percentCompleted, 
          remainingTimeMessage: remainingTime 
        })
      }
    }
  };

  axios.post(`${configJSON.baseURL.baseURL}/${configJSON.sendChatMessage}${this.state.chatId || chatId}`, formData, config)
  .then(response => { 
    const {sendMessageToAbly}=sendMessageThroughChannels(this.state.createdChatName,this.callGetFetchUserList);
    sendMessageToAbly(this.state.messageText)
    this.setState({messageText:'', selectedFiles:[]})
    this.messageToggleDown()
    this.getChatDetails(this.state.chatId)
      // this.getFocusedUserList('')
  })
  .catch(error => { 
if(error.response?.data?.errors[0]?.token){
  toast.error("Token expired, please login", { toastId: 'apiError'})
}
  })
  .finally(() => {
    this.setState({
      loaderMessage: false, 
      remainingTimeMessage: 0,
      progressMessage: 0, 
    })

  });
  
  };

  getChatDetails=(chatId:string, markRead: boolean | null = null )=>{
    this.setState({loader:true, callListApiFlag: !!markRead })
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChat}/${chatId}?is_mark_read=${markRead}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }


  deleteChat=()=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };

    let formData = new FormData();
    if(this.state.deleteMultiple){
      formData.append("chat_ids", JSON.stringify(this.state.checkedItems));
    }
   else{
    formData.append("chat_ids", JSON.stringify([this.state.chatId]));
   }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteChatApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChat}/delete_chats`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    ); 

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }


  getFocusedUserList = (searchValue:string) => {
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFousedUsersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      searchValue?`${configJSON.createChat}?search_query=${searchValue}`:`${configJSON.createChat}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChat=(accountId:string)=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
 
   let formData = new FormData();
   formData.append("chat[name]", uuidv4());
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createChatApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChat}?account_id=${accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  messageToggleUp=()=>{
    this.setState({ toggleMessageBoxOpenClose: true,massgeMaxRows:6 })
    const messageBox=document.getElementById('messageBoxexistUser');
    if(messageBox){
      messageBox.style.height=`calc(-556px + 100vh)`
    }
   
  const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
  if(newMessageBox){
    newMessageBox.style.height="calc(-437px + 100vh)"  // 441
  }


  const emptyBox= document.getElementById('emptyBox'); 
  if(emptyBox){
    emptyBox.style.height="calc(100vh - 497px)" 
  }

  const fileBox=document.getElementById('uploadfilebox');
    if(fileBox && this.state.selectedFiles.length>0){
      fileBox.style.display="none"
    }
  const textMessageBox= document.getElementById('textarea'); 
  if(textMessageBox){
    textMessageBox.style.height="132px" 
    textMessageBox.style.overflowY="auto" 
  }


  }
  messageToggleDown=()=>{
    this.setState({ toggleMessageBoxOpenClose: false,massgeMaxRows:4 })
 
    this.existMessageBoxSetup()
    this.newMessageBoxSetUp()
  this.emptyBoxSetUp()

 
  const textMessageBox= document.getElementById('textarea'); 
  if(textMessageBox){
    textMessageBox.style.height=window.screen.height > 850 ? "88px" : "50px" 
    textMessageBox.style.overflowY="auto" 
  }

  }
  existMessageBoxSetup=()=>{
    const messageBox=document.getElementById('messageBoxexistUser');
    if(messageBox){
      const fileBox=document.getElementById('uploadfilebox');
      if(fileBox && this.state.selectedFiles.length>0){
        let height=0
        if(this.state.selectedFiles.length<=2){
          height=this.state.selectedFiles.length*67 // 73
        }
        else{
          height=146
        } 
      messageBox.style.height=window.screen.height > 850 ?`calc(-512px - ${height}px + 100vh)`:`calc(-425px - ${height}px + 100vh)`
        fileBox.style.display="block"
      }
      else{
        messageBox.style.height = this.handleCondition(window.screen.height > 850, `calc(-485px + 100vh)`, `calc(-425px + 100vh)`)
      }
    }
  }

  newMessageBoxSetUp=()=>{
    const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
  if(newMessageBox){
  
    const fileBox=document.getElementById('uploadfilebox');
    if(fileBox && this.state.selectedFiles.length>0){
      let height=0
      if(this.state.selectedFiles.length<=2){
        height=this.state.selectedFiles.length*67
      }
      else{
        height=146
      } 
      newMessageBox.style.height=`calc(-393px - ${height}px + 100vh)`
      fileBox.style.display="block"
    }
    else{
      newMessageBox.style.height="calc(-393px + 100vh)"  //397
    }
  }
  }
  emptyBoxSetUp=()=>{
    const emptyBox= document.getElementById('emptyBox'); 
    if(emptyBox){
      const fileBox=document.getElementById('uploadfilebox');
      if(fileBox && this.state.selectedFiles.length>0){
        let height=0
        if(this.state.selectedFiles.length<=2){
          height=this.state.selectedFiles.length*67
        }
        else{
          height=146
        } 
        emptyBox.style.height=`calc(-453px - ${height}px + 100vh)`
        fileBox.style.display="block"
      }
      else{
        emptyBox.style.height="calc(100vh - 453px)" 
      }
    }
  
  
  }

  checkForReport = () => {
    const user = this.state.existUsersList.find((item) => item.id === this.state.chatId);
    return user ? user.attributes.is_reported === true : false;  
  }

  selectUser=(userInfo:AccountDetailsItemObj, chatInfo:ChatObj)=>{
    this.checkForReport()
    if(!this.state.manageConversationClicked){
      this.setState({selectedUser:userInfo,newMessageFlow:false,chatId:chatInfo.id, hideList: true,
        createdChatName:chatInfo.attributes.name,isStared:chatInfo.attributes.starred,accountId:Number(userInfo.id),selectedFiles:[]})
       if(chatInfo.attributes && chatInfo.attributes.last_message && chatInfo.attributes.last_message.attributes.created_at ){
        this.setState({lastMessageTime:chatInfo.attributes.last_message?.attributes?.created_at})
    }
 }
 this.messageToggleDown()
 this.getChatDetails(chatInfo.id, true)
    if (chatInfo.attributes.unread_messages_count && (chatInfo.attributes.unread_messages_count > 0)) {
      const updatedChatList = this.state.existUsersList.filter((chat: ChatObj) => !chat.attributes.is_reported).map((chat) => { return (chatInfo.id === chat.id) ? { ...chat, attributes: { ...chat.attributes, unread_messages_count: 0 } } : chat })
      this.setUnreadCount(updatedChatList)
    }
if(chatInfo && chatInfo.attributes && chatInfo.attributes.unread_messages_count  && chatInfo.attributes.unread_messages_count > 0){
this.getFocusedUserList('')
}

  }


  markMessagesUnread=(event :{stopPropagation:()=>void})=>{
    event.stopPropagation()
    this.setState({viewChatThreeDotsStatus:false, isUnread:this.state.chatId})
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markChatMessagesUnreadApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createChat}/${this.state.chatId}/mark_unread`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  starTheChat=()=>{
    let token = getLoginToken();
    const header = {
      'Content-Type': configJSON.ApiContentType,
      token: token
    };

    let formData = new FormData();
   formData.append("account_id",this.state.accountId.toString());
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.starUnstarChatApiCallId = requestMessage.messageId;
    const apiUrl=this.state.starUnstarChat=='star'? `${configJSON.createChat}/${this.state.chatId}/star_chat`:`${configJSON.createChat}/${this.state.chatId}/unstar_chat`

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleStarUnstarMarkAsUnreadResponse=(apiRequestCallId:string)=>{
    if(apiRequestCallId === this.markChatMessagesUnreadApiCallId){
      this.getFocusedUserList('')
    }
    if(apiRequestCallId === this.starUnstarChatApiCallId){
      this.getFocusedUserList('')
      this.getChatDetails(this.state.chatId, true)

    }
  }

  handleResponseWithData=(apiRequestCallId:string,responseJson:ResponseObj)=>{
    if(apiRequestCallId === this.getAllNewUsersApiCallId ){
      let data=JSON.parse(JSON.stringify(responseJson.data))
      this.setState({newUsersList:data, newSearchLoader: false})
  }
  if(apiRequestCallId === this.getFousedUsersApiCallId && responseJson.data  && (responseJson.data as Array<ChatObj>)){
    let data=JSON.parse(JSON.stringify(responseJson.data))
    this.handleFousedUserResp(data)  
    this.setUnreadCount(data.filter((chat: ChatObj) => !chat.attributes.is_reported))
}
if(apiRequestCallId === this.createChatApiCallId && responseJson && responseJson.data && !Array.isArray(responseJson.data) ){
  this.setState({createdChatName:responseJson.data.attributes.name,chatId:responseJson.data.id})
  this.sendMessageToSelectedUser()
} 

if(apiRequestCallId === this.getChatDetailsApiCallId && responseJson && responseJson.chat){
  let data=JSON.parse(JSON.stringify(responseJson))
  let UserList = this.state.existUsersList.map(user => {
    return user.id === data.chat.data.id ? {...user, attributes: {...user.attributes, unread_messages_count: 0}} : user
  })
this.setState({messageListInfo:data,isStared:responseJson.chat.data.attributes.starred,loader:false },()=>{
  if(this.state.callListApiFlag){
    this.setState({
      callListApiFlag: false,
      existUsersList : UserList
    })
  }
  
  this.setUnreadCount(UserList.filter((chat: ChatObj) => !chat.attributes.is_reported))
  this.scrollToLastMessage()
})
}
if(apiRequestCallId === this.getUserProfileApiId || apiRequestCallId === this.sendMessageOptionSetApiCallId){
  let data=JSON.parse(JSON.stringify(responseJson))
  this.handleUserProfileData(data.data)
  
}
  }

  handleFousedUserResp = (data:Array<ChatObj>) => {
    if(!this.state.isSubscribedToAbly){
      for (const element of data) {
        sendMessageThroughChannels(element.attributes.name,this.callGetFetchUserList);
      }
      this.setState({isSubscribedToAbly:true})
    }

    if (this.state.searchReceptient){
      this.setState({existSearchUsersList:data})
    } else if(this.state.newMessageFlow && data){
        this.handleFousedUserRespInNewUserFlow(data)
      }
      else if(this.state.chatId){
        this.handleFousedUserRespInNewUserFlow(data)
      }
      else{ 
        this.handleFousedUserRespInExistUser(data)  
    }
  }


  handleFousedUserRespInNewUserFlow=(data:Array<ChatObj>)=>{
    let existChat=data.find((element:ChatObj)=>element.id==this.state.chatId)
        if(existChat){
          let fistUser=existChat.attributes.account_details.data.find((element:AccountDetailsItemObj)=>existChat?.attributes.current_user_id != Number(element.id))
          if(fistUser && existChat.attributes.last_message){
            this.setState({existUsersList:data,selectedUser:fistUser,chatId:existChat.id,createdChatName:existChat.attributes.name, hideList: true,
              accountId:Number(fistUser.id),isStared:existChat.attributes.starred,newMessageFlow:false,isConversationStartWithNewUser:false,
              lastMessageTime:existChat.attributes.last_message.attributes.created_at})
              if(this.state.isUnread !== existChat.id) {
                this.getChatDetails(existChat.id, true)
              }
              this.setState({
                isUnread : ""
              })
          }
        }
  }

  handleFousedUserRespInExistUser=(data:Array<ChatObj>)=>{
    if(this.state.redirectUserInfo && data.length>0){
      this.setRedirectUserExistUserInfo(data);
    }
    else if(this.state.redirectUserInfo && data.length==0){
      this.setState({existUsersList:[]})
      this.setNewUserInfo()
    }
    else if(data.length>0){
      this.setDefaultUserInfo(data);
    }
    else{
      this.setState({existUsersList:[],selectedUser:null,chatId:'',createdChatName:'', hideList: false,
        accountId:0,isStared:false,lastMessageTime:''})
    }
  }
  setDefaultUserInfo=(data:Array<ChatObj>)=>{
    const filteredList = data.filter((chat) => !chat.attributes.is_reported)
    if (filteredList.length <= 0) {
      return
    }
    let firstData = filteredList[0]

    let firstUser = firstData.attributes.account_details.data
      .find((element: AccountDetailsItemObj) => firstData.attributes.current_user_id != Number(element.id))

    if (firstData.attributes.last_message) {
      this.setState({ lastMessageTime: firstData.attributes.last_message.attributes.created_at })
    }
    if (firstUser) {
      this.setState({
        existUsersList: data, selectedUser: firstUser, chatId: firstData.id, createdChatName: firstData.attributes.name,
        accountId: Number(firstUser.id), isStared: firstData.attributes.starred, hideList: true
      })
      this.getChatDetails(firstData.id, true)
    } 
  }
setRedirectUserExistUserInfo=(data:Array<ChatObj>)=>{
  let matchedElement = data.find((object:ChatObj)=>object.attributes.account_details.data.some((element:AccountDetailsItemObj)=>this.state.redirectUserInfo?.data.attributes.account_details.id==Number(element.id)))
  if(matchedElement){
   let fistUser=matchedElement.attributes.account_details.data.find((element:AccountDetailsItemObj)=>this.state.redirectUserInfo?.data.attributes.account_details.id==Number(element.id))
   if(matchedElement.attributes.last_message){
     this.setState({lastMessageTime: matchedElement.attributes.last_message.attributes.created_at})
   }
   if(fistUser){
     this.setState({existUsersList:data,selectedUser:fistUser,chatId:matchedElement.id,createdChatName:matchedElement.attributes.name,
       accountId:Number(fistUser?.id),isStared:matchedElement.attributes.starred, hideList: true})
     this.getChatDetails(matchedElement.id, true)
   }

 }else{

   this.setState({existUsersList:data})
   this.setNewUserInfo()
 }
}

  setUnreadCount = (data: Array<ChatObj>) => {
    let unreadCount = data.filter((chat) => chat.attributes.unread_messages_count && (chat.attributes.unread_messages_count > 0)).length
    window.dispatchEvent(new CustomEvent("GET_CHAT_COUNT", { detail: { unreadCount } }))
  }

  setNewUserInfo=()=>{
    if(this.state.redirectUserInfo){
      this.setState({selectedUser:null,chatId:'',createdChatName:'',
      accountId:0,isStared:false,lastMessageTime:'', hideList: false})
      this.setState({
        newMessageFlow: true,
        searchnewMessageReceptient:'',
        searchNewMessagePopUpStatus:false,
        isConversationStartWithNewUser:true,
        selectedNewUserInfo:this.state.redirectUserInfo,
        selectedFiles:[],
        newChat: this.state.redirectUserInfo?.data.attributes.account_details.id.toString()
      });
    }
  
    this.messageToggleDown()
  }

  isChatReported = () => {
    let isReported = false 
    if(this.state.existUsersList.length > 0) {
      let currentChat = this.state.existUsersList.filter((chat) => chat.id === this.state.chatId)
      isReported = currentChat.length > 0 ? currentChat[0].attributes.is_reported : false
    }
    return isReported
  }

  renderMessageInput = () => {
    return !this.isChatReported() && (this.state.isSubscribed || this.state.newMessageFlow )
  }

  handleDeleteAPIResp = (responseJson:ResponseObj) => {
    if( responseJson && responseJson.message == "Chat conversations removed successfully" ) {
      if(this.state.checkedItems.length > 0) {
        if( this.state.checkedItems.includes(Number(this.state.chatId))) {
          this.setState({
            chatId: '',
            checkedItems: [],
            messageListInfo: null,
            lastMessageTime: ''}, () => {
            this.getFocusedUserList('')
          })
        }
        else{ this.setState({checkedItems:[],messageListInfo:null,lastMessageTime:''}, () => {
            this.getFocusedUserList('')
        })}
      }
      else{
        this.setState({
          chatId:'',
          messageListInfo:null,
          lastMessageTime:''}, () => {
          this.getFocusedUserList('')
          })
      }
   
    }
  }

  handleReasonAPIResp = (apiResponse: {flag_reasons?: UserReasons[]}) => {
    if (apiResponse.flag_reasons) {
      this.setState({ userReasons: apiResponse.flag_reasons })
    }
  }

  handleAddReasonAPIResp = (apiResponse: { message?: string } | { error?: string }) => {
    if ('error' in apiResponse) {
      toast.error(apiResponse.error)
    } else if ('message' in apiResponse) {
      this.setState({
        reportedSuccessfully: true,
        reportClick: false,
        selectedReason: {
          id: 0,
          reason: "",
          created_at: "",
          updated_at: "",
          otherReason: ""
        }
      })
      this.setState({
        existUsersList: this.state.existUsersList.map((item) => item.id === this.state.chatId ? {...item, attributes: {...item.attributes, is_reported: true}} : item),
      }, () => {
        this.setState({
          selectedUser: null,
        newChat: '',
        checkedItems: [],
        messageListInfo: null,
        lastMessageTime: '',
        })
      })
    }
  }

  handleResponse = (response: {errors: [{"token": "expired"}]}) => {
    Object.values(response.errors[0]).forEach((item: any) => toast.error(item))
  }

  isUnreadMessagesPresent=(item:ChatObj)=>{
    if (item.attributes.unread_messages_count && item.attributes.unread_messages_count > 0) {
      return true
    }
    else {
      return false
    }
  }

  handleReportDialogue = () => {
    if (!this.state.reportClick) {
        this.getReasonsList()
    }
    this.setState({ 
      reportClick: !this.state.reportClick, 
      viewChatThreeDotsStatus: false 
    })
  }

  handleSelect = (reason: {
    id: number,
    reason: string,
    created_at: string,
    updated_at: string,
    otherReason: string
  }) => {
    this.setState({ selectedReason: reason })
  }

  handleSessionResponse=(message:Message)=>{
    const token: string = message.getData(
      getName(MessageEnum.SessionResponseToken)
    );
    runEngine.debugLog("TOKEN", token);
    const messageData =
      message.getData(getName(MessageEnum.SessionResponseData))
      ;
    const accountId: number = messageData?.meta?.id;
    this.setState({ accountId });
    if (token) {
      this.setState({ token }, () => this.getChatList(token));
    }
  }

  handleChatListResponse=(chatList:Array<IChatResponse>)=>{
    const results = chatList.map((item: IChatResponse) => {
      const findAccountMuteResult = item.attributes.accounts_chats.find(
        (item) => item.attributes.account_id === this.state.accountId
      )?.attributes.muted;
      return {
        id: item.id,
        name: item.attributes.name,
        muted:
          findAccountMuteResult ??
          item.attributes.accounts_chats[0].attributes.muted,
        unreadCount:
          item.attributes.accounts_chats[0].attributes.unread_count,
        lastMessage: item.attributes.messages?.attributes?.message,
      };
    });
    this.setState({
      chatList: results,
    });
  }

  handleCloseReportMessage = () => {
    this.setState({
      reportedSuccessfully: false
    })
  }

  handleDeleteDialogClose = () => {
    this.setState({ dialogOpen: false});
  };
  handleDeleteDialogOpen=(event:{stopPropagation:()=>void})=>{
    if(event){
      event.stopPropagation()
    }
    this.setState({ dialogOpen: true,viewChatThreeDotsStatus:false});
  }

  disbableSendButton=()=>{
    let messageText = this.state.messageText.trim()
    if(this.state.chatId || this.state.newChat){
      if(messageText && this.state.selectedFiles.length>0){
        return false
      }
      else if(messageText=='' && this.state.selectedFiles.length>0){
        return false
      }
      else if(messageText && this.state.selectedFiles.length==0){
  return false
      }
      else if(messageText=="" && this.state.selectedFiles.length==0){
        return true
      }
    }
    else{
      return true;
    }

  }
   handleTokenError = (responseJson : ResponseObj, webApiRequestCallId : string) => {
    if(responseJson.errors && responseJson.errors[0].token) {
      this.setState({loader:false})
      toast.error("Token expired, please login", { toastId: 'apiError'})
    }
  }

    handleError=(responseJson : ResponseObj, webApiRequestCallId : string)=>{
    if(responseJson.error) {
      this.setState({loader:false})
      toast.error(responseJson.error, { toastId: 'apiError'})
    }
  }
    handleMessageError=(responseJson : ResponseObj, webApiRequestCallId : string)=>{
    if(responseJson.message && webApiRequestCallId === this.sendMessageApiCallId) {
      toast.error(responseJson.message, { toastId: 'apiError'})
    }
  }

  handleErrorsToAPI=(webApiRequestCallId : string)=>{
  if(webApiRequestCallId == this.getAllNewUsersApiCallId || webApiRequestCallId == this.sendMessageApiCallId || 
    webApiRequestCallId == this.getChatDetailsApiCallId || webApiRequestCallId == this.createChatApiCallId || 
    webApiRequestCallId == this.getFousedUsersApiCallId || webApiRequestCallId == this.deleteChatApiCallId || 
    webApiRequestCallId == this.markChatMessagesUnreadApiCallId || webApiRequestCallId == this.starUnstarChatApiCallId){
return true;
  }
  else{
   return false
  }
  }
  checkForSubscription = async () => {
    const token = await getStorageData("authToken")

    const headers = {
      "Content-Type": configJSON.apiContentType,
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkForSubscriptionAPI}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFileDelete = (index:number) => {
    this.setState((prevState) => ({
      selectedFiles: prevState.selectedFiles.filter((file, i) => i !== index),
    }),()=>{
      if(this.state.selectedFiles.length<=2){
        let height=this.state.selectedFiles.length*67 // 73
        const messageBox=document.getElementById('messageBoxexistUser');
        if(messageBox){
          messageBox.style.height=window.screen.height > 850 ? `calc(-512px - ${height}px + 100vh)` : `calc(-425px - ${height}px + 100vh)`
        }
        
        const newMessageBox= document.getElementById('newMsgMessagegriditem1'); 
        if(newMessageBox){
          newMessageBox.style.height=`calc(-393px - ${height}px  + 100vh)` 
        }
  
        const emptyBox= document.getElementById('emptyBox'); 
        if(emptyBox){
          emptyBox.style.height=`calc(100vh  - ${height}px - 453px)`
        }
    
      }
    });
    };
    downloadFile=(url:string, fileName:string)=>{
      const a = document.createElement('a');
      a.href = url;
      a.target="_blank"
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    addReasons1 = async () => {
      const token = await getStorageData("authToken")
      const headers = {
          token,
      };
      const formData = new FormData();
      formData.append("chat_id", this.state.chatId);
      this.state.selectedReason.otherReason ? formData.append("other_reason", this.state.selectedReason.otherReason) : formData.append("reason_id", `${this.state.selectedReason.id}`)
      const addReportsDataMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addReasonsApiCallId = addReportsDataMessage.messageId;
      addReportsDataMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.addReasonsApiEndPoint
      );
      addReportsDataMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
      );
      addReportsDataMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formData
      );
      addReportsDataMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethod
      );
      runEngine.sendMessage(addReportsDataMessage.id, addReportsDataMessage);
  };

  getReasonsList = async () => {
    const token = await getStorageData("authToken")

    const headers = {
        "Content-Type": configJSON.apiContentType,
        token,
    };
    const reasonsData = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    reasonsData.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    this.reasonApiCallId = reasonsData.messageId;
    reasonsData.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.resonEndPointApi}`
    );
    reasonsData.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethod
    );
    runEngine.sendMessage(reasonsData.id, reasonsData);
}
getUserProfile = async() => {
  let send_message_option= await getStorageData('send_message_option')
if(send_message_option) {
this.setState({send_message_option:send_message_option})
return;
}

let token = getLoginToken();
const headers = {
'Content-Type': configJSON.ApiContentType,
token: token
};

const getUserProfile = new Message(getName(MessageEnum.RestAPIRequestMessage));

this.getUserProfileApiId = getUserProfile.messageId;
getUserProfile.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getProfile}`);
getUserProfile.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
getUserProfile.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);

runEngine.sendMessage(getUserProfile.id, getUserProfile);
}
handleUserProfileData = (responseData : {attributes: {photo: string, selected_group_context: string,send_message_option:string}}) => {
if(responseData){
this.setState({
  send_message_option:responseData.attributes.send_message_option
})
setStorageData('send_message_option',responseData.attributes.send_message_option)
}
}

setSendMessageOption = () => {
let token = getLoginToken();
const header = {
'Content-Type': configJSON.ApiContentType,
token: token
};

let formData = new FormData();
formData.append("send_message_option", this.state.send_message_option);

const requestMessage = new Message(
getName(MessageEnum.RestAPIRequestMessage)
);

this.sendMessageOptionSetApiCallId = requestMessage.messageId;
requestMessage.addData(
getName(MessageEnum.RestAPIResponceEndPointMessage),
`${configJSON.sendMessageOptionSetApi}`
);

requestMessage.addData(
getName(MessageEnum.RestAPIRequestHeaderMessage),
JSON.stringify(header)
);

requestMessage.addData(
getName(MessageEnum.RestAPIRequestBodyMessage),
formData
);
requestMessage.addData(
getName(MessageEnum.RestAPIRequestMethodMessage),
configJSON.putApiMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleSubscriptionResponse=(responseJson:{user_subscription:{data:{attributes:{status:string,subscription_details:{chat_limits: string}}}}})=>{
  if (responseJson.user_subscription) {
    const chatLimits = responseJson.user_subscription.data.attributes.subscription_details.chat_limits;
    const chatLimitNumber = this.handleConditionNumber(typeof chatLimits === 'string', parseInt(chatLimits, 10), Number(chatLimits));

    this.setState({
      isSubscribed: responseJson.user_subscription.data.attributes.status === "active",
      chatLimit: this.handleConditionNumber(responseJson.user_subscription.data.attributes.status === "active", chatLimitNumber, 0 as number) 
    })
  } else{
    this.setState({
      isSubscribed: false,
      chatLimit: 0
    })
  }
}
scrollToLastMessage=()=>{
  let element=document.getElementById("messageBoxexistUser");
  if(element){
    const toplast = element.lastElementChild;
    if(toplast){
      setTimeout(() => {
        toplast.scrollIntoView();
      }, 500);
    }
  }
}

  renderCompanyName = (role: string, currentTitle: string | null = "", companyName: string | null = "") => {
    if (role === "recruiter") {
      return companyName
    } else if (role === "candidate" && currentTitle) {
      return `${TruncateWithTooltip(currentTitle, 30)}`
    }
  }

  // Customizable Area End
}
