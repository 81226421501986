import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid, IconButton } from '@material-ui/core';
import { Formik, Form, Field, FormikProps } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import CustomTextFieldCommon from '../../../../components/src/CustomTextFieldCommon';
import CustomSelectDropdownComponent from '../../../../components/src/CustomSelectDropdownComponent.web';
import CustomTextAreaComponent from '../../../../components/src/CustomTextAreaComponent.web';
import CustomCheckBoxWithOneTitle from '../../../../components/src/CustomCheckBoxWithOneTitle.web';
import CustomTextFieldMultipleInput from '../../../../components/src/CustomTextFieldMultipleInput.web';
import { QuestionFormate, S } from '../JobListingController';
import { addRoundIcon, cancleicon, circleIcon, crossBlueIcon, deleteIcon, penIcon } from '../assets';
import CustomSwitchComponent from '../../../../components/src/CustomSwitchComponent.web';
import { Autocomplete } from '@material-ui/lab';
import AutoCompleteCommon from '../../../../components/src/AutoCompleteCommon.web';
import { City, Country, ICity, ICountry } from 'country-state-city';
import { ClassNameMap } from '@material-ui/styles';
import { getCommaSeparatedValue } from '../../../../../packages/components/src/Utils.web';

const useStyles = makeStyles({
    root: {
        padiing: "56px",
        position: "relative",
        '&::-webkit-scrollbar': {
            width: '12px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: "#1F497D",
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: "#1F497D",
        },
        "& .MuiPaper-root": {
            minWidth: "400px"
        }
    },
    jobDetailsContainer: {
        "borderRadius": "16px",
        "border": "1px solid #F4F4F4",
        "background": "#FFF",
        display: "flex",
        "flexDirection": "column",
        "margin": "0px",
        "width": "100%"
    },
    root1: {
        "background": "#FFF",
        "padding": "0px 20px 20px 22px !important",
        "position": "relative",
        "marginTop": "10px"
    },
    title: {
        "color": "#17365D",
        "fontSize": "24px",
        "fontWeight": "bold",
        "lineHeight": "26px",
        "fontFamily": "Arial",
        "display": "flex",
        "alignItems": "center"
    },
    page: {
        background: "#F3F4F8",
        padding: "50px 124px 50px 124px",
    },
    form: {
        background: "#FFFFFF",
        width: "auto",
        margin: "0px",
        padding: "0px 24px 24px 20px",
        borderRadius: "8px 8px 32px 8px",
        position: "relative"
    },
    form1: {
        background: "#FFFFFF",
        width: "auto",
        margin: "0px",
        borderRadius: "8px 8px 32px 8px",
        position: "relative"
    },
    noBtn: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #1F497D",
        "background": "#FFF",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none",
        "width": "auto"
    },
    yesBtn: {
        "border": "1px solid #1F497D",
        "color": "#FFFFFF",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "#1F497D"
        },
        "borderRadius": "8px",
        "background": "#1F497D",
        "display": "flex",
        "width": "auto",
        "height": "56px",
        "padding": "16px",
        "justifyContent": "center",
        "alignItems": "center",
        "gap": "8px",
        "textTransform": "none",
        '&.Mui-disabled': {
            color: '#FFFFFF', // Adjust the color of disabled text
            background: '#CBD5E1', // Adjust the background color of disabled button
            border: '1px solid #CBD5E1', // Adjust the border color of disabled button
            '&:hover': {
                background: '#1F497D', // Keep the same background color on hover
            },
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "25px !important"
    },
    btnContainer1: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginBottom: "5px",
        paddingTop: "170px !important"
    },
    cardTitle: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "bold",
        "lineHeight": "22px",
        "marginBottom": "32px"
    },
    uploadTxt: {
        "display": "flex",
        "width": "121px",
        "justifyContent": "flex-end",
        "alignItems": "center",
        "gap": "6px",
        "borderRadius": "4px",
        "background": "#F4F4F4",
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "whiteSpace": "nowrap",
        "marginLeft": "13px"
    },
    errorText: {
        "color": "#DC2626",
        "fontFamily": "Arial",
        "fontSize": "12px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "18px",
        "display": "flex"
    },
    bottomLeft: {
        position: "absolute",
        bottom: "0px",
        left: "0px"
    },
    topRight: {
        position: "absolute",
        top: "0px",
        right: "0px"
    },
    toText: {
        fontFamily: "Arial",
        color: "#9C9C9C",
        fontWeight: "bold",
        fontSize: "14px"
    },
    addQuestion: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "14px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "1px solid  #6D97C1",
        "background": "#FFF",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none",
        "width": "auto",
        "display": "flex",
        "alignItems": "center"
    },
    radioOption: {
        fontFamily: "Arial",
        fontSize: "14px",
        fontWeight: "lighter",
        display: "flex",
        cursor: "pointer",
        color: "#1F497D"
    },
    inputOption: {
        border: "0px white",
        color: "#1F497D",
        cursor: "pointer"
    },
    addQuestionNormal: {
        "color": "#17365D",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "marginLeft": "20px",
        "&:hover": {
            background: "white"
        },
        "borderRadius": "8px",
        "border": "0px solid  #6D97C1",
        "background": "#FFF",
        "height": "56px",
        "padding": "10px 16px",
        "textTransform": "none",
        "width": "auto",
        "display": "flex",
        "alignItems": "center"
    },
    paddingToBod: {
        padding: "0px 24px 0px 20px",
    }
});

export interface SimpleDialogProps {
    isDisabled: () => boolean;
    open: boolean;
    onClose: () => void;
    state: S;
    setStep: (value: number, values: object) => void;
    addOption: (value: QuestionFormate) => void;
    addNewQuestion: (value: QuestionFormate) => void;
    handleQuestionChange: (event: any, newQuestion: QuestionFormate) => void;
    handleOptionChange: (event: any, newQuestion: QuestionFormate, qid: string) => void;
    handleRequiredChange: (event: any, newQuestion: QuestionFormate) => void;
    handleRemoveQuestion: (newQuestion: QuestionFormate) => void;
    handleTypeChange: (event: any, newQuestion: QuestionFormate) => void;
    handleRemoveOption: (newQuestion: QuestionFormate, qid: string) => void;
    focuseId: string;
    setFocusId: (value: string) => void;
    createJob: (value: object, isdraft?: boolean) => void;
    companyListHandler: (value: React.ChangeEvent<HTMLInputElement>) => void;
    handleFormOneSubmit: (values: {
        job_title: string,
        company_name: string,
        country: string,
        city: string,
        job_categories: string,
        job_description: string,
        post_expire_after: string
    }) => void;
    handleFormTwoSubmit: (values: {
        salary_from: string,
        salary_to: string,
        disclose: boolean | string,
        experience_required: string,
        industry_type: string,
        department: string,
        skills_required: string[],
        education: string,
        currency: string,
    }) => void;
    handleFormThreeSubmit: (values: QuestionFormate[]) => void;
    handleCountryChange: (value: { value: string; label: string; }[]) => void;
}

const validationSchema = Yup.object().shape({
    job_title: Yup.string().required('Job title is a required field'),
    company_name: Yup.string().required('Company name is a required field'),
    country: Yup.string().required('Country is a required field'),
    city: Yup.string().required('City is a required field'),
    job_categories: Yup.string().required('Job type is a required field'),
    job_description: Yup.string().required('Job description is a required field'),
    post_expire_after: Yup.string().required('Post Expires After is a required field'),
});

const validationSchemaForSeconForm = Yup.object().shape({
    salary_from: Yup.string().required('Salary is a required field'),
    salary_to: Yup.string()
    .required('Salary is a required field')
    .when('salary_from', (salary_from: string, schema: Yup.ObjectSchema) => {
      return schema.test({
        name: 'salary-to-greater-than-salary-from',
        message: 'Salary TO must be greater than Salary FROM',
        test: function (salary_to: string) {
          if (!salary_from || !salary_to) return true;
          return parseFloat(salary_to) > parseFloat(salary_from);
        },
      });
    }),
    experience_required: Yup.string().required('Experience is a required field'),
    industry_type: Yup.string().required('Industry is a required field'),
    education: Yup.string().required('Education is a required field'),
    department: Yup.string().required('Department is a required field'),
    skills_required: Yup.array().required('Skills is a required field').of(
        Yup.string().required('Skills is a required field')
    ),
    currency: Yup.string().required('Currency is a required field')
});
const validationSchemaForThirdForm = Yup.object().shape({
    questions: Yup.array().of(
        Yup.object().shape({
            question: Yup.string().required('Question is required'),
            type: Yup.string().required('Type is required'),
            options: Yup.array().when('type', {
                is: (type) => type !== 'Short Answer',
                then: Yup.array().of(
                    Yup.object().shape({
                        title: Yup.string().required('Title is required'),
                        id: Yup.string().required('ID is required'),
                    })
                ).required('Options are required'),
                otherwise: Yup.array(),
            }),
        })
    ),
});

interface FormThreeProps {
    state: S,
    classes: ClassNameMap<"radioOption" | "inputOption" | "form" | "title" | "root" | "page" | "jobDetailsContainer" | "root1" | "form1" | "noBtn" | "yesBtn" | "btnContainer" | "btnContainer1" | "cardTitle" | "uploadTxt" | "errorText" | "paddingToBod">,
    focuseId: string,
    addOption:  (value: QuestionFormate) => void,
    setFocusId: (value: string) => void,
    handleQuestionChange: (event: any, newQuestion: QuestionFormate) => void;
    handleOptionChange: (event: any, newQuestion: QuestionFormate, qid: string) => void;
    handleRequiredChange: (event: any, newQuestion: QuestionFormate) => void;
    handleRemoveQuestion: (newQuestion: QuestionFormate) => void;
    handleTypeChange: (event: any, newQuestion: QuestionFormate) => void;
    handleRemoveOption: (newQuestion: QuestionFormate, qid: string) => void;
}

let renderFormThree = (props: FormThreeProps) => {
    let { state, classes, focuseId, handleQuestionChange, handleTypeChange, handleOptionChange, handleRemoveOption, addOption, handleRequiredChange, handleRemoveQuestion, setFocusId } = props;
    return <Grid container spacing={3} style={{ margin: "0px", width: "auto", }}>
        {
            state.formThree.map((item: QuestionFormate) => {
                return <Grid item id="question-container" container sm={12} spacing={2} style={{ display: "flex", justifyContent: "space-between", margin: "0px", padding: "0px", marginTop: "15px" }}>
                    <Grid item sm={8} style={{ paddingTop: "0px", flex: 1, margin: "0px", paddingLeft: "35px" }} key={item.id} className={classes.paddingToBod}>
                        {
                            focuseId === item.id ? <Field
                                component={CustomTextFieldCommon}
                                type="string"
                                variant="outlined"
                                title=""
                                placeHolder="Writing a question here"
                                onChangeControlled={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                                ) => {
                                    handleQuestionChange(e, item)
                                }}
                                controlledValue={item.question_text}
                            />
                                : <Typography className={classes.radioOption} >{item.question_text}</Typography>}
                    </Grid>
                    <Grid item sm={4} style={{ paddingTop: "0px", flex: 1, margin: "0px", paddingRight: "35px" }} className={classes.paddingToBod}>
                        {
                            focuseId === item.id && <Field
                                component={CustomSelectDropdownComponent}
                                type="string"
                                variant="outlined"
                                title=""
                                placeHolder=""
                                name={`${item.id}Type`}
                                options={[{ value: "Multiple Choice", label: "Multiple Choice" }, { value: "Dropdown", label: "Dropdown" }, { value: "Short Answer", label: "Short Answer" }]}
                                onChangeControlled={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                                ) => {
                                    handleTypeChange(e, item)
                                }}
                                controlledValue={item.question_type}
                            />
                        }
                    </Grid>
                    <Grid item container style={{ margin: "0px", padding: "10px 35px" }} className={classes.paddingToBod}>
                        {
                            item.question_type !== "Short Answer" && <Grid item container spacing={2} id="opt-container">
                                {
                                    item.options.map((opt: { title: string, id: string }, index) => <Grid item container sm={12} style={{ display: "flex", alignItems: "center" }} id="input-opt">
                                        <Grid item style={{ flex: 1, display: "flex", alignItems: "center" }}>
                                            {item.question_type == "Dropdown" ? <Typography className={classes.radioOption}> {index + 1}.</Typography> : <img src={circleIcon} alt="icon" />} &nbsp;&nbsp;
                                            <input
                                                disabled={(focuseId !== item.id) || opt.title === "Other..."}
                                                placeholder={""}
                                                key={opt.id}
                                                id="input-option"
                                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                                                ) => {
                                                    handleOptionChange(e, item, opt.id)
                                                }}
                                                value={opt.title}
                                                className={classes.inputOption}
                                                style={opt.title === "Other..." ? { color: "#9C9C9C" } : {}}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {<img src={cancleicon} id="remove-opt" alt="icon" onClick={() => handleRemoveOption(item, opt.id)} style={{ cursor: "pointer" }} />}
                                        </Grid>
                                    </Grid>
                                    )
                                }
                                {
                                    focuseId === item.id && <Grid item container sm={12} style={{ display: "flex", alignItems: "center" }} id="input-opt">
                                        <Grid item style={{ flex: 1, display: "flex", alignItems: "center" }}>
                                            {item.question_type == "Dropdown" ? <Typography className={classes.radioOption}> {item.options.length + 1}.</Typography> : <img src={circleIcon} alt="icon" />} &nbsp;&nbsp;
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Typography className={classes.radioOption}
                                                    id="add-opt"
                                                    onClick={() => {
                                                        addOption({
                                                            ...item, options: item.options.some(item => item.title === "Other...") ?
                                                                [...item.options.slice(0, item.options.length - 1), { title: `Option ${item.options.length}`, id: uuidv4() }, { title: "Other...", id: uuidv4() }] :
                                                                [...item.options, { title: `Option ${item.options.length + 1}`, id: uuidv4() }]
                                                        })
                                                    }} style={{ color: "#9C9C9C", paddingRight: "5px" }}>{!item.options.some(item => item.title === "Other...") && item.question_type === "Multiple Choice" ? "Add option or" : "Add option"}</Typography>
                                                {!item.options.some(item => item.title === "Other...") && item.question_type === "Multiple Choice" && <Typography id="add-input" onClick={() => addOption({ ...item, options: [...item.options, { title: "Other...", id: uuidv4() }] })} className={classes.radioOption}> add ”other ”</Typography>}
                                            </div>
                                        </Grid>
                                        <Grid>
                                            {
                                                item.options.length === 0 && <Typography className={classes.errorText}>Options are mandatory</Typography>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        }
                        <Divider style={{ width: "100%", marginTop: "16px" }} />
                    </Grid>

                    <Grid item style={{ display: "flex", justifyContent: "flex-end", paddingRight: "36px" }} sm={12}>
                        <Field
                            component={CustomSwitchComponent}
                            type="string"
                            variant="outlined"
                            title="Required"
                            placeHolder=""
                            onChangeControlled={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                            ) => {
                                handleRequiredChange(e, item)
                            }}
                            controlledCheck={item.required}
                            value={item.required}
                        />
                        {
                            focuseId !== item.id && <IconButton>
                                <img src={penIcon} alt="icon" onClick={() => setFocusId(item.id)} id="focus-Icon" style={{ cursor: "pointer" }} />
                            </IconButton>
                        }
                        <IconButton onClick={() => handleRemoveQuestion(item)}>
                            <img src={deleteIcon} alt="icon" />
                        </IconButton>
                    </Grid>
                    {state.formThree.length > 0 && <Divider style={{ width: "100%" }} />}
                </Grid>
            })
        }
    </Grid>
}

export default function CreateJobModal(props: SimpleDialogProps) {
    const classes = useStyles();
    const { isDisabled, handleCountryChange, handleFormOneSubmit, handleFormTwoSubmit, handleFormThreeSubmit, companyListHandler, createJob, focuseId, setFocusId, onClose, open, state, setStep, addNewQuestion, addOption, handleQuestionChange, handleTypeChange, handleOptionChange, handleRequiredChange, handleRemoveQuestion, handleRemoveOption } = props;

    let ref = React.useRef<FormikProps<{ salary_from: string; salary_to: string; disclose: string | boolean; experience_required: string; industry_type: string; department: string; skills_required: string[]; education: string; }>>(null);
    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root} >
            <Grid
                item
                container
                className={classes.root1}
                sm={12} 
                id="dialogueContainer"
                tabIndex={0}
                onKeyDown={(event) => {
                    const container = document.getElementById('dialogueContainer');
                    if (container) {
                        if (event.key === 'ArrowDown') {
                            container.scrollBy(0, 50);
                        } else if (event.key === 'ArrowUp') {
                            container.scrollBy(0, -50);
                        }
                    }
                }}>
                <Grid item container>
                    <Grid item sm={12} xs={12} style={{ padding: "13px" }}>
                        <Grid item container sm={12} style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography className={classes.title}>
                                {
                                    state.currentStep !== 1 && <span id="back-btn" onClick={() => {
                                        setStep(state.currentStep - 1, state.currentStep === 2 && ref.current ? { ...(ref.current as FormikProps<{ salary_from: string; salary_to: string; disclose: string | boolean; experience_required: string; industry_type: string; department: string; skills_required: string[]; education: string; }>).values } : {});
                                    }} style={{ cursor: "pointer" }}>
                                        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.75203 1.23852C8.38453 0.871016 7.79203 0.871016 7.42453 1.23852L1.19203 7.47102C0.899531 7.76352 0.899531 8.23602 1.19203 8.52852L7.42453 14.761C7.79203 15.1285 8.38453 15.1285 8.75203 14.761C9.11953 14.3935 9.11953 13.801 8.75203 13.4335L3.32203 7.99602L8.75953 2.55852C9.11953 2.19852 9.11953 1.59852 8.75203 1.23852Z" fill="#17365D" />
                                        </svg>
                                        &nbsp;
                                        &nbsp;
                                    </span>
                                }
                                Create Job Post ({state.currentStep}/3)
                            </Typography>
                            <img src={crossBlueIcon} alt="icon" style={{ position: "absolute", right: "30px", top: "20px", cursor: "pointer" }} onClick={onClose} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {state.currentStep === 1 && <Grid container spacing={1} className={classes.jobDetailsContainer}>
                <Formik
                    initialValues={state.formOne}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(values) => {
                        setStep(2, values);
                        handleFormOneSubmit(values);
                    }}
                >
                    {({ values, errors, setFieldValue, setFieldError, touched, isValid, validateForm, handleBlur }) => (
                        <Form style={{ width: "inherit" }}>
                            <Grid className={classes.form}>
                                <Grid container spacing={3} style={{ margin: "0px", width: "auto", }}>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="job_title"
                                            component={CustomTextFieldCommon}
                                            type="string"
                                            variant="outlined"
                                            title="Job Title"
                                            placeHolder=""
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={state.companyList}
                                            autoSelect
                                            onChange={(event) => {
                                                const selectedValue = (event.target as HTMLInputElement).textContent || '';
                                                setFieldValue("company_name", selectedValue);
                                            }}
                                            onBlur={handleBlur}
                                            value={{ value: values.company_name, label: values.company_name }}
                                            getOptionLabel={(option) => option?.label ? option.label : ''}
                                            renderInput={(params) => <AutoCompleteCommon
                                                name="company_name"
                                                title="Company"
                                                errors={errors}
                                                params={params}
                                                placeHolder=""
                                                touched={touched.company_name}
                                                value={values.company_name}
                                                onChange={(event) => {
                                                    companyListHandler(event);
                                                    setFieldValue("company_name", event.target.value)
                                                }} />}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={Country.getAllCountries().map((country: ICountry) => ({
                                                value: country.name,
                                                label: country.name,
                                                cId: country.isoCode
                                            }))}
                                            onBlur={handleBlur}
                                            value={{ value: values.country, label: values.country, cId: "0" }}
                                            getOptionLabel={(option) => option.label}
                                            onChange={(event, value) => {
                                                setFieldValue("country", (event.target as HTMLElement).textContent)
                                                if (value?.cId) {
                                                    let cities = ((City.getCitiesOfCountry(value.cId) as []).map((city: ICity) => ({
                                                        value: city.name,
                                                        label: city.name,
                                                    })) as { value: string, label: string }[]);
                                                    handleCountryChange(cities);
                                                    if (!(cities.find((city) => city.value === values.city))) {
                                                        setFieldValue("city", "")
                                                    }
                                                }
                                            }}
                                            renderInput={(params) => <AutoCompleteCommon
                                                name="country"
                                                title="Country"
                                                errors={errors}
                                                params={params}
                                                placeHolder=""
                                                touched={touched.country}
                                                onChange={(_event) => { }}
                                            />}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={state.cities}
                                            onBlur={handleBlur}
                                            onChange={(event) => setFieldValue("city", (event.target as HTMLElement).textContent)}
                                            value={{ value: values.city, label: values.city }}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => <AutoCompleteCommon
                                                errors={errors} params={params} title="City"
                                                name="city"
                                                placeHolder=""
                                                touched={touched.city}
                                                onChange={(_event) => { }} />}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="job_categories"
                                            component={CustomSelectDropdownComponent}
                                            type="string"
                                            variant="outlined"
                                            title="Job Type"
                                            placeHolder=""
                                            options={[{ value: "Part Time", label: "Part Time" }, { value: "Full Time", label: "Full Time" }, { value: "Internship", label: "Internship" }]}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="job_description"
                                            component={CustomTextAreaComponent}
                                            type="string"
                                            variant="outlined"
                                            title="Job Description"
                                            placeHolder=""
                                            multiline={true}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="post_expire_after"
                                            component={CustomSelectDropdownComponent}
                                            type="string"
                                            variant="outlined"
                                            title="Post Expires After"
                                            options={[
                                                { value: "1 Week", label: "1 Week" },
                                                { value: "2 Weeks", label: "2 Weeks" },
                                                { value: "3 Weeks", label: "3 Weeks" },
                                                { value: "4 Weeks", label: "4 Weeks" },
                                                { value: "5 Weeks", label: "5 Weeks" },
                                                { value: "6 Weeks", label: "6 Weeks" },
                                                { value: "7 Weeks", label: "7 Weeks" },
                                                { value: "8 Weeks", label: "8 Weeks" },
                                            ]}
                                            defaultValue="4 Weeks"
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12} className={classes.btnContainer}>
                                        <Button disabled={Object.values(values).join("").trim() === ""} className={classes.noBtn} onClick={() => {
                                            createJob({ ...state.formTwo, ...state.formThree, ...values, questions: [...state.formThree], draft_page: 1 }, true);
                                        }}>Save as Draft</Button>
                                        <Button className={classes.yesBtn} type="submit" disabled={!isValid}>Continue</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>}
            {state.currentStep === 2 && <Grid container spacing={1} className={classes.jobDetailsContainer}>
                <Formik
                    initialValues={state.formTwo}
                    validationSchema={validationSchemaForSeconForm}
                    enableReinitialize
                    innerRef={ref}
                    onSubmit={(values) => {
                        setStep(3, values);
                        handleFormTwoSubmit(values as typeof state.formTwo);
                    }}
                >
                    {({ values, errors, setFieldValue, handleChange, isValid }) => (
                        <Form style={{ width: "inherit" }}>
                            <Grid className={classes.form}>
                                <Grid container spacing={3} style={{ margin: "0px", width: "auto", }}>
                                    <Grid item container sm={12} style={{ display: "flex", justifyContent: "space-between" }}>
                                        <Grid item style={{ paddingTop: "0px" }}>
                                            <Field
                                                name="currency"
                                                component={CustomSelectDropdownComponent}
                                                type="string"
                                                variant="outlined"
                                                title="Currency"
                                                placeHolder=""
                                                defaultValue={"$"}
                                                options={[
                                                    { value: "₹", label: "₹" },
                                                    { value: "$", label: "$" },
                                                    { value: "€", label: "€" },
                                                    { value: "£", label: "£" },
                                                    { value: "¥", label: "¥" },
                                                ]}
                                                style={{ width: "53px" }}
                                            />
                                        </Grid>
                                        &nbsp;
                                        &nbsp;
                                        <Grid item style={{ paddingTop: "0px", flex: 1 }}>
                                            <Field
                                                name="salary_from"
                                                component={CustomTextFieldCommon}
                                                type="string"
                                                controlledValue={getCommaSeparatedValue(values.salary_from)}
                                                variant="outlined"
                                                title="Annual Salary"
                                                placeHolder=""
                                                onChangeControlled={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFieldValue("salary_from", event.target.value?.replace(/,/g, ''))
                                                }}
                                            />
                                        </Grid>
                                        <Grid item style={{ paddingTop: "0px", display: "flex", alignItems: "center", height: "100%", padding: "20px 12px 0px 12px" }}>
                                            <Typography className={classes.toText}>to</Typography>
                                        </Grid>
                                        <Grid item style={{ paddingTop: "20px", flex: 1 }}>
                                            <Field
                                                name="salary_to"
                                                component={CustomTextFieldCommon}
                                                controlledValue={getCommaSeparatedValue(values.salary_to)}
                                                type="string"
                                                variant="outlined"
                                                title=""
                                                placeHolder=""
                                                onChangeControlled={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                                    setFieldValue("salary_to", event.target.value?.replace(/,/g, ''))
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ paddingTop: "0px", paddingLeft: "16px" }}>
                                        <CustomCheckBoxWithOneTitle name="disclose" onChange={handleChange} checked={!!values.disclose} title="Disclose Salary" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="experience_required"
                                            component={CustomSelectDropdownComponent}
                                            type="string"
                                            variant="outlined"
                                            title="Experience"
                                            placeHolder=""
                                            options={[
                                                { value: "0-2 Years", label: "0-2 Years" },
                                                { value: "2-4 Years", label: "2-4 Years" },
                                                { value: "4-6 Years", label: "4-6 Years" },
                                                { value: "6-8 Years", label: "6-8 Years" },
                                                { value: "8-10 Years", label: "8-10 Years" },
                                                { value: "10+ Years", label: "10+ Years" }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="education"
                                            component={CustomTextFieldCommon}
                                            type="string"
                                            variant="outlined"
                                            title="Education"
                                            placeHolder=""
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="industry_type"
                                            component={CustomTextFieldCommon}
                                            type="string"
                                            variant="outlined"
                                            title="Industry Type"
                                            placeHolder=""
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="department"
                                            component={CustomTextFieldCommon}
                                            type="string"
                                            variant="outlined"
                                            title="Department"
                                            placeHolder=""
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} style={{ paddingTop: "0px" }}>
                                        <Field
                                            name="skills_required"
                                            component={CustomTextFieldMultipleInput}
                                            type="string"
                                            variant="outlined"
                                            title="Skills"
                                            placeHolder="Press enter to add skill"
                                        />
                                    </Grid>
                                    <Grid item sm={12} className={classes.btnContainer}>
                                        <Button className={classes.noBtn} onClick={() => createJob({ ...state.formDataFinal, ...values, questions: [...state.formThree], draft_page: 2 }, true)}>Save as Draft</Button>
                                        <Button className={classes.yesBtn} type="submit" disabled={!isValid}>Continue</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>}
            {state.currentStep === 3 && <Grid container spacing={1} className={classes.jobDetailsContainer}>
                <Formik
                    initialValues={state.formThree}
                    enableReinitialize
                    validationSchema={validationSchemaForThirdForm}
                    onSubmit={(values) => {
                        handleFormThreeSubmit(values)
                        createJob({ ...state.formDataFinal, questions: [...state.formThree] })
                    }}
                >
                    {({ values, errors, setFieldValue, handleChange, isValid }) => {

                        return (
                            <Form style={{ width: "inherit" }} onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}>
                                <Grid className={classes.form1} style={{ margin: "0px" }}>
                                    {renderFormThree({state, classes, focuseId, handleQuestionChange, handleTypeChange, handleOptionChange, handleRemoveOption, addOption, handleRequiredChange, handleRemoveQuestion, setFocusId })}
                                    <Grid item container sm={12} className={classes.paddingToBod} style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px" }}>
                                        <Button className={state.formThree.length === 0 ? classes.addQuestion : classes.addQuestionNormal} onClick={() => addNewQuestion({ question_text: "", id: uuidv4(), question_type: "Short Answer", required: false, options: [] })}><img src={addRoundIcon} alt="icon" /> &nbsp;&nbsp;Add Question</Button>
                                    </Grid>
                                    <Divider style={{ width: "100%" }} />
                                    <Grid item sm={12} className={classes.btnContainer} style={{ paddingRight: "30px" }}>
                                        <Button className={classes.noBtn} onClick={() => createJob({ ...state.formDataFinal, ...state.formThree, questions: [...state.formThree], draft_page: 3 }, true)}>Save as Draft</Button>
                                        <Button className={classes.yesBtn} type="submit" disabled={isDisabled()}>Save</Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )
                    }}
                </Formik>
            </Grid>}
        </Dialog>
    );
}

