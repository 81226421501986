import React from "react";

import {
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@material-ui/core";

// Customizable Area Start
import LeaderboardController, {
  Props,
} from "./LeaderboardController.web";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";
import { profileImage, openWhiteMenuIcon, SearchIcon, EndSearchIcon, noSubImg } from "./assets";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FilterToggel from '../../../components/src/FilterButton';
import TableComponent from "../../../components/src/TableComponent";
import { TruncateWithTooltip, getNameFirstLatters, getOrdinalSuffix } from "../../../../packages/components/src/Utils.web";
import { ToastContainer } from "react-toastify";
import BootstrapTooltip from "../../../../packages/blocks/catalogue/src/components/BootstrapTooltip";
// Customizable Area End

export default class Leaderboard extends LeaderboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      leaderboardWeb,
      pageIndexWeb,
      totalPageWeb,
      isLoadingWeb,
      moreLoadingWeb,
    } = this.state;

    return (
      // Customizable Area Start
      <div>
        {
          this.handleCondition(!this.state.hasSubscription, <div
            style={webStyle.noDataContainer as React.CSSProperties}
          >
            <Typography style={webStyle.headerTitle as React.CSSProperties}>Leaderboard</Typography>
            <img src={noSubImg} />
            <Typography style={webStyle.textOne}>
              No Leaderboard access
            </Typography>
            <Typography style={webStyle.textTwo}>
              Unlock exclusive benefits like leaderboard access with a
            </Typography>
            <Typography style={webStyle.textTwo}>
              subscription! Elevate your experience and enjoy premium
            </Typography>
            <Typography style={webStyle.textTwo}>
              features. Subscribe now for more!
            </Typography>
            <Button style={webStyle.subscribButton as React.CSSProperties} id="go-to-sub" onClick={this.navigateToSubscriptionsPage}>
              Subscribe Now
            </Button>
          </div>,
            <Grid container >
              {this.handleCondition(this.state.showRewards,
                <Grid container style={webStyle.container} className="leaderboard-container-class">
                  <Grid item md={3} sm={12} style={webStyle.subContainer} >
                    {
                      this.state.userRole === 'candidate' && <SideBox>
                        <Box style={webStyle.sideBox as React.CSSProperties}>
                          <Box style={webStyle.sideBoxSub} >
                            <Avatar style={webStyle.avatarLeftBox}>
                              <div style={webStyle.avtarLetter}>
                                {getNameFirstLatters(this.state.currentUser?.attributes?.full_name)[0]}
                              </div>
                            </Avatar>
                            <AvatarName>
                              {getNameFirstLatters(this.state.currentUser?.attributes?.full_name)}
                            </AvatarName>
                          </Box>
                          <ProfileTag>
                            <img src={profileImage} alt={'profileImage'} />
                            <Box
                              style={webStyle.postingUpText}
                            >
                              Posting as:
                            </Box>
                            <Box
                              style={webStyle.jobTitleText}
                            >
                              <BootstrapTooltip title={this.state.currentUser?.attributes?.current_title}>
                                <span>
                                  {TruncateWithTooltip(this.state.currentUser?.attributes?.current_title, 20)}
                                </span>
                              </BootstrapTooltip>
                            </Box>
                          </ProfileTag>
                          <Box
                            sx={{
                              width: 'inherit',
                              height: 'auto',
                              borderRadius: '16px',
                              padding: '13px',
                            }}
                            data-test-id="gotocfscorecalculation"
                            style={{ backgroundColor: '#F3F4F9', cursor: "pointer" }}
                            onClick={this.handleScoreCalRedirection}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }} className="progressPercentageWrapper" >
                              <Box sx={{ position: 'relative', display: 'inline-flex' }} className="percentageBar" >
                                <CircularProgress
                                  variant="determinate"
                                  value={100}
                                  style={{
                                    color: '#EEECE1', width: '60px',
                                    height: '60px'
                                  }}
                                />
                                <CircularProgress
                                  variant="determinate"
                                  value={parseFloat(this.state.currentUser?.attributes?.total_progress_percentage ?? "0")}
                                  style={{
                                    color: "#6D97C1", position: 'absolute', top: 0, left: 0, width: '60px',
                                    height: '60px'
                                  }}
                                />
                                <Box
                                  sx={{
                                    top: 0,
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                    position: 'absolute',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    component="div"
                                    style={{ color: "#17365D", fontSize: '14px', fontFamily: "Arial" }}>
                                    {parseFloat(this.state.currentUser?.attributes?.total_progress_percentage ?? "0")}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box ml={1.5}>
                                <ProgressHeading >My Progress</ProgressHeading>
                                <ProgressSubheading >Congratulations! You ranked {getOrdinalSuffix(this.state.currentUser?.attributes?.overall_rank)} in the list </ProgressSubheading>
                              </Box>
                            </Box>
                          </Box>
                          <Box >
                            <RankingButton data-test-id="reward-button" onClick={this.handleRewards}>
                              Go to my ranking
                              <StyledIconStyle />
                            </RankingButton>
                          </Box>
                        </Box>
                      </SideBox>
                    }
                  </Grid>
                  <Grid item md={this.state.userRole === 'candidate' ? 9 : 12} sm={12}>
                    <Box
                      style={webStyle.rightContainer}
                    >
                      <Grid style={webStyle.rightSubContainer}>
                        <Box style={webStyle.headerTitleContainer}>
                          <Box
                            style={webStyle.LeaderboardTitle}
                          >
                            Leaderboard
                          </Box>
                          <Box style={webStyle.headerSubConatiner}>
                            <Box style={webStyle.filterContainer}>
                              <FilterToggel
                                handleCountryChange={this.handleCountryChange}
                                handleCityChange={this.handleCityChange}
                                handleExperienceChange={this.experienceHandleClickOne}
                                handleSkillChange={this.skillsByHandleClickOne}
                                selectedSkills={this.state.selectedSkills}
                                selectedExperiences={this.state.selectedExperiences}
                                selectedCountry={this.state.selectedCountryRadio}
                                selectedCity={this.state.selectedCityRadio}
                                experienceList={this.state.experienceList}
                                skillsList={this.state.skillsList}
                                isoCode={this.state.isoCode}
                                setISOCode={this.setISOCode}
                                clearFilter={this.clearFilter}
                              />
                            </Box>
                            <RootAutoComplete
                              name="find job list"
                              placeholder="Search"
                              variant="outlined"
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setSearchUser(event.target.value)
                              }}
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={SearchIcon} alt="icon" />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src={EndSearchIcon} alt="icon" />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Box>
                        </Box>
                        <Box style={webStyle.avgScoreContainer}>
                          <SubHeading data-test-id="avg_potential_score_text">
                            Average Score: {Math.round(this.state.average_potential_score)}
                          </SubHeading>
                          <SubHeading data-test-id="high_potential_score_text">
                            Highest Score: {Math.round(this.state.highest_potential_score)}
                          </SubHeading>
                        </Box>
                        <Box
                          style={{
                            borderRadius: '8px',
                            overflow: "hidden"
                          }}
                        >
                          <TableComponent handleRecruiterNavigation={this.handleRecruiterNavigation} data={this.state.userslist} currentUserData={this.state.currentUser} loadMoreData={this.handleScroll} isFetching={this.state.isInfiniteLoading} handleSendConnectRequest={this.handleSendConnectRequest} handleOpenChat={this.handleOpenChat} />
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>,
                <Grid container style={{ padding: '20px' }} >
                  <Grid item md={12} sm={12} style={{ marginLeft: '45px', marginRight: '50px' }}
                  >
                    <Box style={{
                      background: "#ffffff",
                      borderRadius: '16px',
                      overflow: "hidden"
                    }}>
                      <Grid style={{ padding: '20px', overflow: "hidden" }}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                          <Box style={{
                            fontFamily: 'Arial',
                            fontSize: '14px',
                            fontWeight: 700,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '5px'
                          }}>
                            <ArrowBackIosIcon data-test-id="back-button" style={{ width: '15px', height: '15px', cursor: 'pointer' }} onClick={this.handlePrevious} />
                            My Rankings</Box>
                          <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <Box style={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}>
                              <FilterToggel
                                selectedSkills={this.state.selectedSkills}
                                selectedExperiences={this.state.selectedExperiences}
                                handleCountryChange={this.handleCountryChange}
                                selectedCountry={this.state.selectedCountryRadio}
                                handleCityChange={this.handleCityChange}
                                selectedCity={this.state.selectedCityRadio}
                                handleExperienceChange={this.experienceHandleClickOne}
                                experienceList={this.state.experienceList}
                                handleSkillChange={this.skillsByHandleClickOne}
                                skillsList={this.state.skillsList}
                                isoCode={this.state.isoCode}
                                setISOCode={this.setISOCode}
                                clearFilter={this.clearFilter}
                              /></Box>
                            <RootAutoComplete
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                this.setSearchUser(event.target.value)
                              }}
                              placeholder="Search"
                              variant="outlined"
                              name="find"
                              InputLabelProps={{ shrink: true }}
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img src={EndSearchIcon} alt="icon" />
                                  </InputAdornment>
                                ),
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={SearchIcon} alt="icon" />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Box>
                        </Box>
                        <Box style={{
                          borderRadius: '8px',
                          overflow: "hidden"
                        }}>
                          <TableComponent  handleRecruiterNavigation={this.handleRecruiterNavigation} data={this.state.userslist} currentUserData={this.state.currentUser} loadMoreData={this.handleScroll} isFetching={this.state.isInfiniteLoading} handleSendConnectRequest={this.handleSendConnectRequest} handleOpenChat={this.handleOpenChat} />
                        </Box>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )
        }

        <ToastContainer />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  headerTitle: {
    fontFamily: "Arial",
    fontWeight: "bold",
    color: "#000000",
    fontSize: "14px",
    position: "absolute",
    top: "18px",
    left: "25px"
  },
  noDataContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFFFFF",
    borderRadius: "16px",
    maxWidth: "830px",
    width: "100vw",
    height: "84vh",
    margin: "25px 0px",
    position: "relative"
  },
  textOne: {
    fontFamily: "Arial",
    fontWeight: "bold",
    fontSize: "24px",
    color: "#17365D",
    padding: "18px 0px 8px 0px"
  },
  textTwo: {
    fontFamily: "Arial",
    fontWeight: "lighter",
    fontSize: "16px",
    color: "#9C9C9C",
    lineHeight: "22px"
  },
  subscribButton: {
    color: "#FFFFFF",
    backgroundColor: "#1F497D",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "none",
    marginTop: "28px"
  },
  container: { padding: '20px', maxWidth: "1245px" },
  subContainer: { paddingLeft: '10px' },
  sideBox: { display: 'flex', flexDirection: 'column', gap: '10px' },
  sideBoxSub: { display: "flex", alignItems: "center" },
  avatarLeftBox: { background: '#6D97C1', color: "#FFFFFF", width: '50px', height: '50px', },
  avtarLetter: { marginTop: '4px' },
  postingUpText: {
    fontFamily: 'Arial',
    fontSize: '10px',
    fontWeight: 400,
    marginLeft: '5px',
    color: '#767373'
  },
  jobTitleText: {
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: 400,
    color: '#1F497D',
    marginLeft: '5px'

  },
  rightContainer: {
    marginLeft: '20px',
    background: "#ffffff",
    height: '73vh',
    borderRadius: '16px',
    overflow: 'hidden',
    paddingBottom: "100px"
  },
  rightSubContainer: { padding: '20px' },
  headerTitleContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  LeaderboardTitle: {
    fontSize: '14px',
    fontWeight: 700,
    fontFamily: 'Arial',
  },
  headerSubConatiner: { alignItems: 'center', gap: '10px', display: 'flex', },
  filterContainer: { display: 'flex', alignItems: 'center', cursor: 'pointer', },
  avgScoreContainer: { display: 'flex', marginBottom: '10px', gap: '10px', overFlow: "hidden" }
}

const SideBox = styled(Box)(({ theme }) => ({
  background: "#ffffff",
  borderRadius: '16px',
  padding: '20px',
  marginBottom: '10px',
}));
const AvatarName = styled(Typography)(({ theme }) => ({
  marginLeft: '15px',
  color: "#17365D",
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Arial'
}));
const SubHeading = styled(Typography)(({ theme }) => ({

  color: "#1F497D",
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Arial'
}));

const ProgressHeading = styled(Typography)(({ theme }) => ({
  color: "#17365D",
  fontSize: '12px',
  fontWeight: 700,
  fontFamily: 'Arial'
}));
const ProgressSubheading = styled(Typography)(({ theme }) => ({
  maxWidth: '110px',
  color: "#17365D",
  fontSize: '10px',
  fontWeight: 400,
  fontFamily: 'Arial'
}));
const ProfileTag = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '26px',
  padding: '3px 12px 3px 12px',
  borderRadius: ' 7px',
  background: '#F3F4F9',
  alignItems: 'center'

}));
const RankingButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '44px',
  padding: '10px 16px 10px 16px',
  background: '#6D97C1',
  color: "#FFFFFF",
  borderRadius: ' 8px',
  fontSize: '16px',
  fontWeight: 700,
  fontFamily: 'Arial',
  display: 'flex',
  textTransform: 'none',
  justifyContent: "space-between",
  '&:hover': {
    background: "#6D97C1",
  },

}));

const StyledIconStyle = styled(ArrowForwardIosIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
}));

const RootAutoComplete = styled(TextField)({
  alignItems: 'center',
  width: "200px",
  borderRadius: "8px",
  display: 'flex',
  height: "29px",
  position: 'relative',
  "& .MuiFormHelperText-contained": {
    marginLeft: '0px'
  },
  "& .MuiInputBase-root": {
    background: "rgba(28, 28, 28, 0.05)",
    border: "0px solid #9C9C9C",
    borderRadius: "8px",
    height: "29px",
    padding: '2.5px 5px !important',
    width: "200px"
  },
  "& .MuiSvgIcon-root": {
    display: 'none'
  },
  "& .MuiAutocomplete-input": {
    padding: "5.5px 4px !important"
  },
  "& .custom-dropdown-icon": {
    height: '24px',
    width: '24px',
    backgroundSize: 'cover',
    cursor: 'pointer',
    right: 0,
    backgroundImage: `url(${openWhiteMenuIcon})`,
    position: 'absolute',
    pointerEvents: 'none',
    display: 'none'
  },
  "& .MuiOutlinedInput-input": {
    borderRadius: "8px",
    border: "0px solid #9C9C9C",
    background: "#FFF",
    "fontFamily": "Arial",
    color: "#6D97C1",
    backgroundColor: "transparent",
    padding: '5px',
    "fontWeight": "lighter",
    "fontSize": "14px",
    width: "100%",
    "fontStyle": "normal",
    "lineHeight": "22px",
    "&:hover": {
      border: "0px solid #6D97C1",
    },
    "&:focus": {
      border: "0px solid #1F497D",
    },
    "&::placeholder": {
      "color": "rgba(28, 28, 28, 0.2)",
      "fontFamily": "Arial",
      "fontSize": "14px",
      "fontStyle": "normal",
      "fontWeight": "lighter",
      "lineHeight": "22px"
    },
    paddingLeft: "20px !important"
  },
  "& .MuiInputAdornment-positionEnd": {
    position: "absolute",
    right: "10px"
  },
  "& .MuiInputAdornment-positionStart": {
    position: "absolute",
    padding: "2px"
  },
  "& .MuiOutlinedInput-root": {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "transparent !important"
  },

})


// Customizable Area End
