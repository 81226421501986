import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
    getName
} from "framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { toast } from "react-toastify";
import { ICountry, Country, City, ICity } from 'country-state-city'
import { debounce } from 'lodash';
import { getLocation, getLoginToken, getUserId } from "../../../components/src/Utils.web";
import axios, { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import * as Yup from 'yup';
import { CountryData } from "react-phone-input-2";
import parsePhoneNumber, { CountryCode } from "libphonenumber-js";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface InitialValue {
    phone_number: string,
    name: string,
    city_field: string,
    country_field: string,
    personal_email_id: string,
    work_email_id: string,
    full_name: string,
    bio: string,
    current_title: string,
    location: string,
    email: string,
    full_phone_number: string,
    salary: string,
    experience: string,
    current_company: string,
    future_self: string,
    photo: any
    company_indusry: string,
    company_name?: string
}

interface S {
    // Customizable Area Start
    groupContext: string;
    progress:number;
    loader:boolean;
    token: string;
    remainingTime:number;
    isProfileUploaded: boolean;
    profileBlob: string | ArrayBuffer | null;
    companyList: { value: string, label: string }[]
    countries: { value: string; label: string }[];
    initialValues: InitialValue,
    isDivVisible: boolean,
    cities: { value: string; label: string; }[]
    countryList:{
        name:string
        id:number,
    }[]
    selectedCountryName:string,
    countryListOpen:boolean,
    cityList:{
        name:string
        id:number,
    }[],
    selectedCityName:string,
    cityListOpen:boolean,
    crossOpen:boolean,
    showVerifiyEmail:boolean,
    isFirstTimeLogin: boolean,
    loading: boolean,
    coords: {
        latitude: number,
        longitude: number,
      };
    phoneNumber: string
    phoneInputFormat: any
    workEmailVerified: boolean
    personalEmailVerified: boolean,
    emailToDisplay: string,
    emailIdType?: "personalEmailVerified" | "workEmailVerified",
    disableButton: string,
    verifyLoader: boolean,
    otpError: string | boolean,
    otpValue: string,
    timer: boolean,
    resend: boolean,
    showWorkEmailError: boolean,
    showPersonalEmailError: boolean,
    formValues?: FormValues
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

type FormValues = {
    name: string;
    phone_number: string;
    country_field: string;
    city_field: string;
    work_email_id: string;
    personal_email_id: string;
    bio: string;
    full_name: string;
    location: string;
    current_title: string;
    full_phone_number: string;
    email: string;
    experience: string;
    salary: string;
    future_self: string;
    current_company: string;
    company_indusry: string;
    photo: any;
}
export default class UserProfileEdit1Controller extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getUserProfileDataApiId: Message | string = ""
    companyListApiId: Message | string = ""
    updateUserDetailsApiId: Message | string = ""
    currentLocationApiId: Message | string = ""
    sendOtpApiId: Message | string = ""
    reSendOtpApiId: Message | string = ""
    verifyOtpApiId: Message | string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.SessionResponseToken),
            getName(MessageEnum.SessionSaveMessage)
        ];

        this.state = {
            groupContext: "",
            progress:0,
            loader:false,
            token: '',
            remainingTime:0,
            initialValues: {
                phone_number: "",
                name: "",
                city_field: "",
                country_field: "",
                personal_email_id: "",
                work_email_id: "",
                full_name: "",
                bio: "",
                current_title: "",
                location: "",
                full_phone_number: "",
                email: "",
                experience: "",
                salary: "",
                future_self: "",
                current_company: "",
                company_indusry: "",
                photo: "",
                company_name: ""
            },
            phoneNumber: "",
            phoneInputFormat: {},
            cities: [],
            countries: [],
            companyList: [{ value: '', label: '' }],
            profileBlob: "",
            isProfileUploaded: false,
            isDivVisible: false,
            countryList:[
                {
                    id:0,
                    name:"country 1"
                },
                {
                    id:1,
                    name:"country 2"
                },
                {
                    id:2,
                    name:"country 3"
                }, {
                    id:3,
                    name:"country 4"
                }, {
                    id:5,
                    name:"country 5"
                }
            ],
            countryListOpen:false,
            selectedCountryName:"",
            cityListOpen:false,
            cityList:[
                {
                    id:0,
                    name:"city 1"
                },
                {
                    id:1,
                    name:"city 2"
                },
                {
                    id:2,
                    name:"city 3"
                }, {
                    id:3,
                    name:"city 4"
                }, {
                    id:5,
                    name:"city 5"
                }
            ],
            selectedCityName:"",
            showVerifiyEmail:false,
            crossOpen:false,
            isFirstTimeLogin: false,
            coords: {
                latitude: 0,
                longitude: 0,
              },
            loading: false,
            workEmailVerified: false,
            personalEmailVerified: false,
            emailToDisplay: "",
            disableButton: "",
            verifyLoader: false,
            otpError: "",
            otpValue: "",
            timer: true,
            resend: false,
            showWorkEmailError: false,
            showPersonalEmailError: false,
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.getUserData();
        this.setState({
            countries: Country.getAllCountries().map((country: ICountry) => ({
                value: country.name,
                label: country.name,
            }))
        })

        let isFirstTimeLogin= await  getStorageData("setProfile") || ""
        isFirstTimeLogin = JSON.parse(isFirstTimeLogin)

        this.setState({isFirstTimeLogin})
        !isFirstTimeLogin && this.getCurrentLocation().finally(() => {
            this.getUserCurrentLocationApi()
        })

      const autoFocus= await  getStorageData("autofocus")
      const element = document.getElementById(autoFocus);
        if (element) {
            element.focus();
        }
    }

    getValidationSchema = () => {
        return Yup.object().shape({
            full_name: Yup.string().required('Name is a required field').max(30, "Full name must not be more than 30 characters"),
            full_phone_number: Yup.string().required("Contact Number is a required field"),
            country_field: Yup.string().required('Country is a required field'),
            city_field: Yup.string().required('City is a required field'),
            work_email_id: Yup.string().email("Invalid email")
                .required('Work Email Id is a required field'),
            personal_email_id: Yup.string().email("Invalid email")
                .required('Personal Email Id is a required field'),
        });
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if(this.state.workEmailVerified && prevState.initialValues.work_email_id && this.state?.formValues?.work_email_id !== undefined && (prevState.initialValues.work_email_id !== this.state?.formValues?.work_email_id)) {
            this.setState({
                workEmailVerified: false 
            })
        } else if (!this.state.workEmailVerified && prevState.initialValues.work_email_id && (prevState.initialValues.work_email_id === this.state?.formValues?.work_email_id)) {
            this.setState({
                workEmailVerified: true
            })
        }
        
        if(this.state.personalEmailVerified && prevState.initialValues.personal_email_id && this.state?.formValues?.personal_email_id !== undefined && (prevState.initialValues.personal_email_id !== this.state?.formValues?.personal_email_id)) {
            this.setState({
                personalEmailVerified: false 
            })
        } else if (!this.state.personalEmailVerified && prevState.initialValues.personal_email_id && (prevState.initialValues.personal_email_id === this.state?.formValues?.personal_email_id)) {
            this.setState({
                personalEmailVerified: true
            })
        }
    }

    handleLocationChange = (setFieldValue: (field: string, value: string | null, shouldValidate?: boolean | undefined ) => void, event: React.ChangeEvent<{}>, value: { value: string; label: string; cId: string; } | null , values: InitialValue) => {
        setFieldValue("country_field", (event.target as HTMLElement).textContent, true);
        if (value?.cId) {
            let cities = ((City.getCitiesOfCountry(value.cId) as []).map((city: ICity) => ({
                value: city.name,
                label: city.name,
            })) as { value: string, label: string }[]);
            this.handleCountryClicked(cities);
            setTimeout(() => {
                if (!(cities.find((city) => city.value === values.city_field))) {
                    setFieldValue("city_field", "")
                }
            })
        }
    }

    handleCurrentValues = (formVal: FormValues) => {
        const newFormValues = {...formVal}
        this.setState({
            formValues: newFormValues
        })
    }

    getCurrentLocation = async (): Promise<void> => {
        return getLocation()
            .then((position) => {
                this.setState({
                    coords: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    },
                })
            })
            .catch(() => {
                this.setState({
                    coords: { latitude: 0, longitude: 0 },
                });
            })
    };

    setPhoneInput = (text:string, country:any, setFieldValue: any) => {
        this.setState({phoneNumber: text, phoneInputFormat: country.format})
        setFieldValue('full_phone_number', text)
    }

    handleValidPhone = () => {
        const { phoneNumber, phoneInputFormat } = this.state;
        let phoneLengthCount = 0;
        for(let char of phoneInputFormat) {
          if(char ===".")
            phoneLengthCount++
        }
        if(phoneNumber.length === phoneLengthCount)
            return true;
        else
        return false
      }

    isPhoneValid = ({ phone, country }: {
        phone: string;
        country: CountryData | {};
    }) => {
        const { countryCode: countryCodePhoneInput, dialCode } = country as CountryData;
        if (!countryCodePhoneInput || !dialCode) return true;
        const countryCode = countryCodePhoneInput.toUpperCase() as CountryCode;
        const phoneWithoutDialCode = phone.replace(dialCode, '');
        const phoneNumber = parsePhoneNumber(phoneWithoutDialCode, countryCode);
        return phoneNumber?.isValid();
    };

    checkForEmailValue = (emailIdType: "personalEmailVerified" | "workEmailVerified", email: string, error?: string) =>  {
        if (email && !error && this.state.disableButton !== emailIdType ) {
            this.setState({
                emailToDisplay: email,
                emailIdType: emailIdType,
                disableButton: emailIdType,
            })
            this.sendOtpCall(email)
        }
    }

    checkForDisableButton = (emailIdType: "personalEmailVerified" | "workEmailVerified", initialEmail: string, currentEmail: string, verified: boolean) => {
        return this.state.disableButton === emailIdType || !currentEmail || ((initialEmail === currentEmail) && verified)
    }

    handleVerifyCondition = (condition: boolean, truePart: React.ReactElement, falsePart: string) => {
        return condition ? truePart : falsePart
    }    

    handleChangeOTP = (value: string) => {
        this.setState({ otpValue: value });
      };      

    handleCountryOpenClick = () => {
        this.setState({countryListOpen:!this.state.countryListOpen})
    }
    handleSelectCountryCall = (name:string) => {
        this.setState({ 
            selectedCountryName: name,
            countryListOpen: false 
        });
    };
    handleCity=()=> {
        this.setState({cityListOpen:!this.state.cityListOpen})
    }
    handleSelectCity = (name:string) => {
        this.setState({ 
            selectedCityName: name,
            cityListOpen: false 
        });
    };
    handleVerifyClick=()=>{
        this.setState({showVerifiyEmail:!this.state.showVerifiyEmail})
    }

    handleCloseClick=()=>{
        this.setState({
            crossOpen: !this.state.crossOpen, 
            showVerifiyEmail: !this.state.showVerifiyEmail,
            otpValue: '',
            otpError: ''
        })
    }
  
    handleCancel=()=>{
        const navigateTo: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateTo.addData(getName(MessageEnum.NavigationTargetMessage), "RecruiterProfile");
        navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateTo);
    }

    createUrl = (url : File | string) => {
        if(url instanceof File) {
            return URL.createObjectURL(url)
        } return url
    }

    handleConditionRender = (condition: string | boolean | any, truePart:  React.ReactElement | string | any, falsePart: string | React.ReactElement | any) => {
        return condition ? truePart : falsePart
    }

    handleVerifyCall = () => {
        this.setState({ loading: true });
      };

      handleFalseClick = () => {
        this.setState({loading: false})
      }
    
    
    replaceNullWithEmptyData = (obj: FormValues & {city: string, country: string} ): FormValues => {
        let newObj: FormValues = { ...obj };

        newObj.country_field = obj.country ?? '';
        newObj.city_field = obj.city ?? '';
    
        Object.keys(newObj).forEach((key) => {
            if (key !== 'country_field' && key !== 'city_field') {
                if (newObj[key as keyof FormValues] == null) {
                    newObj[key as keyof FormValues] = '';
                }
            }
            if(key === "full_phone_number") {
                let str = "";
                newObj.full_phone_number.split("").forEach((objects : any) => str = str + ".")
                this.setState({phoneInputFormat: str})
                this.setState({phoneNumber: newObj.full_phone_number})
            }
        });
        return newObj;
    }

    handleGetUserProfileDataApi = (responseJson: { data: { attributes: InitialValue & { id: number, city: string, country: string, selected_group_context: string} }; errors?: { token: string }[] }) => {
        if(responseJson.data) {
            const { id, ...res } = responseJson.data.attributes;
            
            res.personal_email_id = res.personal_email_id === null ? res.email : res.personal_email_id
            res.work_email_id = res.work_email_id === null ? res.email : res.work_email_id

            let data = this.replaceNullWithEmptyData(res)
            const country = Country.getAllCountries().find(
                (country: ICountry) => data.country_field === country.name
            );
            this.setState({
                personalEmailVerified: this.replaceNullWithEmptyData(res).personal_email_id ? true : false,
                workEmailVerified: this.replaceNullWithEmptyData(res).work_email_id ? true : false,
            })
            
            this.setState({ 
                groupContext: res.selected_group_context,
                initialValues: this.replaceNullWithEmptyData(res),
                cities: country ?
                (City.getCitiesOfCountry((Country.getAllCountries().find((country: ICountry) => country.name === data.country_field) as ICountry).isoCode || "0")?.map((city: ICity) => ({
                    value: city.name, label: city.name,
                })) as { value: string, label: string }[]) :
                []
            })
        }
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            
            if (webApiRequestCallId && responseJson) {
                this.setGroupContextValueFromHeader(responseJson)
                switch (webApiRequestCallId) {

                case this.getUserProfileDataApiId:
                    return this.handleGetUserProfileDataApi(responseJson)
                case this.companyListApiId:
                    return this.handleCompanyList(responseJson)    
                case this.updateUserDetailsApiId:
                    return this.handleUpdateUserProfile(responseJson)
                case this.currentLocationApiId:
                    return this.handleLocationResponse(responseJson)
                case this.sendOtpApiId:
                    return this.handleOtpResponse(responseJson)
                case this.reSendOtpApiId:
                    return this.handleResendOtpResponse(responseJson)    
                case this.verifyOtpApiId: 
                    return this.handleVerifyOtp(responseJson)
    
                default:
                return null

                }
                
            }

            
        }
        // Customizable Area End
    }

    setGroupContextValueFromHeader = (responseJson: { data: { type: string, attributes: InitialValue & { id: number, city: string, country: string, selected_group_context: string} }}) => {
        if(responseJson.data && responseJson.data.type === "profile") {
            this.setState({
                groupContext: responseJson.data.attributes.selected_group_context || ''
            })
        }
    }

    handleUpdateUserProfile = (responseJson: { data: string }) => {
        if (responseJson) {
            if (responseJson.data) {
                toast.success("Profile Updated successfully");
                this.setState({
                    loading: false
                })
                this.props.navigation.goBack();
            }
        }
    }

    handleLocationResponse = (responseJson : {error: string} | { country: string, city: string }) => {
        if("error" in responseJson && responseJson.error) { toast.error(responseJson.error) }
        if('country' in responseJson && responseJson.country && responseJson.city) {
            this.setState({
                initialValues:
                {
                    ...this.state.initialValues,
                    city_field: responseJson.city,
                    country_field: responseJson.country
                }
            })
            const country = Country.getAllCountries().find(
                country => country.name.toLowerCase() === responseJson.country.toLowerCase()
            );
            
            if (country) { const cities = City.getCitiesOfCountry(country.isoCode) 
            if(cities) { 
                this.setState({
                    cities: cities?.map((city) => {return {value: city.name, label: city.name}})
                })
            }
        }
        }
    }

    handleOtpResponse = (responseJson : { message?: string, error?: string }) => {
        this.setState({
            disableButton: ""
        })
        if(responseJson?.message) {
            this.handleVerifyClick()
            toast.success(responseJson.message);
        } else if('error' in responseJson) {
            toast.error(responseJson.error);
        }
    }

    handleResendOtpResponse = (responseJson : { message?: string, error?: string }) => {
        if(responseJson?.message) {
            toast.success(responseJson.message);
        } else if('error' in responseJson) {
            toast.error(responseJson.error);
        }
    }

    handleVerifyOtp = (responseJson: { verified: string, message: string }) => {
        if (responseJson?.verified) {
            this.setState({
                verifyLoader: false,
                crossOpen: !this.state.crossOpen, 
                showVerifiyEmail: false,
                otpError: false,
                otpValue: '',
                initialValues: {
                    ...this.state.formValues!,
                    work_email_id: this.state.emailIdType === "workEmailVerified" ? this.state.emailToDisplay : this.state.initialValues.work_email_id,
                    personal_email_id: this.state.emailIdType === "personalEmailVerified" ? this.state.emailToDisplay : this.state.initialValues.personal_email_id,
                }
            });
            if(this.state.emailIdType === "personalEmailVerified" || this.state.emailIdType === "workEmailVerified") {
                let emailIdType = this.state.emailIdType
                this.setState({
                    [emailIdType]: true,
                } as unknown as Pick<S, keyof S>)  
            }
            toast.success(responseJson.message)
        } else {
            this.setState({
                otpError: true, verifyLoader: false, otpValue: ''
            });
        }
    }

    handleOTPTimerCall = (val : boolean) => {
        this.setState({
          timer: val,
        });
      };
      

    handleCompanyList = (responseJson: {company_listing: {name: string}[]}) => {
        if(responseJson.company_listing.length > 0) {
            const companyList = responseJson.company_listing?.map((country: {name: string}) => ({
                value: country.name, label: country.name,
            }));
            this.setState({ companyList })
        }
    }

    handleEmailChangeClick = (values: FormValues, handleBlur: (e: any) => void) =>{
        this.setState({
            formValues: {...values}
        })
    }

    handleProfileImageChange = (event: React.ChangeEvent<HTMLInputElement> | null,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        setFieldError: (field: string, message: string | undefined) => void
    ) => {
        if (event && event.currentTarget.files && !event.currentTarget.files[0].type.includes("image")) {
            setFieldError("photo",
            "Please upload .jpg, OR .png OR .jpeg file");
            return;
        } else if (event && event.currentTarget.files && 
            event.currentTarget.files[0].size > 10 * 1024 * 1024) {
            setFieldError("photo", 
            "Please upload file less than 10mb");
            return;

        }
        if (event && event.currentTarget.files && 
            event.currentTarget.files.length > 0) {
            setFieldValue('photo', event.currentTarget.files[0]);
            this.setState({isProfileUploaded: true});
            const reader = new FileReader();
            let x = () => { this.setState({ profileBlob: reader.result }) };
            reader.onloadend = x
            reader.readAsDataURL(event.currentTarget.files[0]);
            x();
        }
    }

    handleProfileStateUpdate = () => {
        this.setState({ isProfileUploaded: false });
    }

    handleCountryClicked = (value: { value: string; label: string; }[]) => {
        this.setState({ cities: value })
    }

    handleDebounce = debounce(async (value: string) => {
        const authToken = await getStorageData("authToken")

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        let userData = { email: value || '' };
        const getUserDetailsOnChange = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );
        this.companyListApiId = getUserDetailsOnChange.messageId;
        getUserDetailsOnChange.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.CompanyListApiEndPoint}`
        );
        getUserDetailsOnChange.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getUserDetailsOnChange.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );
        getUserDetailsOnChange.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );
        runEngine.sendMessage(getUserDetailsOnChange.id, getUserDetailsOnChange);
    }, 200)


    handleCompanyListing = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (value) {
            this.handleDebounce(value);
        }
    };

    storeGroupContextApi = async(groupContext: string) => {
        
        if(this.state.groupContext) {
            return;
        }
        const bodyData = {
            profile: {
              "group_context": groupContext
            }
        }
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
        };
        const groupContextApiCallProfilepage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        groupContextApiCallProfilepage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        groupContextApiCallProfilepage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.selectedGroupContextApi}?token=${await getStorageData("authToken")}`);
        groupContextApiCallProfilepage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        groupContextApiCallProfilepage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
        runEngine.sendMessage(groupContextApiCallProfilepage.id, groupContextApiCallProfilepage);
    }

    storeGroupContext = async(groupContext: string) => {
        const bodyData = {
            profile: {
              "group_context": groupContext,
            },
        }
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
        };
        const groupContextApiMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        groupContextApiMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyData));
        groupContextApiMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.selectedGroupContextApi}?token=${await getStorageData("authToken")}`);
        groupContextApiMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        groupContextApiMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchMethod);
        runEngine.sendMessage(groupContextApiMsg.id, groupContextApiMsg);
    }

    handleGroupContextValue = (fullName: string) => {
        if(this.state.groupContext === this.state.initialValues.full_name && fullName !== this.state.groupContext) {
            this.storeGroupContext(fullName);
        }
    }

    handleFormSubmit = async (data: FormValues) => {
        this.setState({
            showWorkEmailError: this.handleConditionRender(!this.state.workEmailVerified, true, false),
            showPersonalEmailError: this.handleConditionRender(!this.state.personalEmailVerified, true, false)
        })

        this.storeGroupContextApi(data.full_name);
        if(!this.state.workEmailVerified || !this.state.personalEmailVerified) {
            return
        }

        this.handleGroupContextValue(data.full_name)

        const authToken = getLoginToken();
        const startTime = Date.now();
        this.setState({ loader: true })
        let id = getUserId();
        let userData = { ...data };
        const formData = new FormData();
        formData.append("profile[full_name]", userData?.full_name);
        formData.append("profile[api_name]", "candidate_update");
        formData.append("profile[personal_email_id]", userData?.personal_email_id || "");
        formData.append("profile[work_email_id]", userData?.work_email_id || "");
        formData.append("profile[country]", userData?.country_field);
        formData.append("profile[city]", userData?.city_field || "");
        formData.append("profile[bio]", userData?.bio || "");
        formData.append("profile[full_phone_number]", userData?.full_phone_number || "");
        if (userData?.photo instanceof File) {
            formData.append("profile[photo]", 
            userData.photo);
        }
        const config: 
        AxiosRequestConfig = {
            headers: { token: authToken ?? '' },
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                const { loaded, total } = progressEvent;
                if (total) {
                    const percentCompleted = Math.round((loaded * 100) / total);
                    const currentTime = Date.now();
                    const elapsedTime = (currentTime - startTime) / 1000;
                    const uploadSpeed = loaded / elapsedTime;
                    const remainingTime = (total - loaded) / uploadSpeed;
                    this.setState({ progress: percentCompleted, remainingTime: remainingTime })
                }}}; axios.put(`${configJSON.baseURL.baseURL}/${configJSON.updateUserDetailsEndPoint}/:${id}`, formData, config)
            .then(response => { toast.success("Profile Updated successfully");
            if(this.state.isFirstTimeLogin) {
                this.props.navigation.goBack()
            }else {
                this.navigationToCompanyDetail()
            }
            })
            .finally(() => { this.setState({ loader: false, progress: 0, remainingTime: 0 }) });
    }

    navigationToCompanyDetail = () => {
        const navigateTo: Message = new Message(getName(MessageEnum.NavigationMessage));
        navigateTo.addData(getName(MessageEnum.NavigationTargetMessage), "CompanyEditDetails");
        navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigateTo);
    } 

    getUserData = () => {
        const headers = { "Content-Type": configJSON.validationApiContentType, "token": getLoginToken()};
        const getUserDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getUserProfileDataApiId = getUserDataMsg.messageId;
        getUserDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserDetailsEndPoint}`
        );
        getUserDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getUserDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodTypeApiGetUserProfile
        );
        runEngine.sendMessage(getUserDataMsg.id, getUserDataMsg);
    }

    getUserCurrentLocationApi = async() => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await getStorageData("authToken")
        };

        const getCurrentLocationMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)

        );

        this.currentLocationApiId = getCurrentLocationMsg.messageId;

        getCurrentLocationMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.currentlocationEndPoint}?latitude=${this.state.coords.latitude}&longitude=${this.state.coords.longitude}`
        );

        getCurrentLocationMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getCurrentLocationMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(getCurrentLocationMsg.id, getCurrentLocationMsg);
    }

    sendOtpCall = async (email: string, resend?: boolean) => {
        if(resend){
            this.setState({resend: resend, otpError: false, otpValue: ''});
        }
        
        const authToken = await getStorageData("authToken")

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };

        let userData = { email };

        const sendOtpMsg = new Message(getName(MessageEnum.RestAPIRequestMessage)
        );

        if(resend) {
            this.reSendOtpApiId = sendOtpMsg.messageId;
        } else {
            this.sendOtpApiId = sendOtpMsg.messageId;
        }

        sendOtpMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendOtpApiEndpoint
        );

        sendOtpMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        sendOtpMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(userData)
        );

        sendOtpMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postMethod
        );

        runEngine.sendMessage(sendOtpMsg.id, sendOtpMsg);
    }

    verifyOtpCall = async () => {
        this.setState({
            verifyLoader: true
        })
        
        const authToken = await getStorageData("authToken")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": authToken
        };
        let userData = { 
            otp: this.state.otpValue, 
            email: this.state.emailToDisplay 
        };
        const verifyOTPmsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.verifyOtpApiId = verifyOTPmsg.messageId;
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.verifyEmailApiEndpoint);
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(userData));
        verifyOTPmsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
        runEngine.sendMessage(verifyOTPmsg.id, verifyOTPmsg);
    }

    // Customizable Area End
}
