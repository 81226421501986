import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Grid, Tabs, Tab, Typography, Box } from "@material-ui/core";
import JobFeedComponent from "./JobFeedComponent";
import JobDetailsCard from "./JobDetailsCard";
import CustomButtonOne from "./CustomButtonOne";
import { JobDetailLoader, JobDetails, StateOfCatelogue } from "../CatalogueController1";
import { Link } from "react-router-dom";
function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}

interface Job {
    id: string;
    type: string;
    attributes: {
        job_title: string;
        company_name: string;
        city: string;
        country: string;
        disclose: boolean;
        status: string;
        created_at: string;
    };
}

type FormValues = {
    full_phone_number: string,
    email: string,
    resume: string | File,
    cover_letter: string | File
}

type Props = {
    setSkillToShow: () => void,
    handleApplyJob: (type: string) => void,
    getTitleOfApplyButton: (type: string) => string,
    jobFeedList: Job[],
    openUserDetailsHandler: (id: string) => void,
    getJobStatusApiHandler?: (id: string) => void,
    jobDetails: JobDetails,
    handleChange?: (event: React.ChangeEvent<{}>, newValue: number) => void,
    tabIndex?: number,
    handleSaveAndUnSaveJob: (flag: boolean) => void,
    applyJobHandler: (status: boolean) => void,
    loadMoreFunction?: () => void,
    applyJob?: boolean,
    submitJobDetailHandler?: (data: FormValues) => void
    jobStatus?: boolean,
    resumeRadioButtonSelected?: (file: any) => void,
    handleShowMore?: () => void,
    showMore?: boolean,
    loader: JobDetailLoader,
    progress:number,
    remainingTime:number,
    handleLikeAndDislikeJob: (flag: boolean) => void,
    mainState: StateOfCatelogue,
    goToSignUpPage: () => void,
    goToLoginPage: () => void,
    handleNavigationToMessage: () => void,
    getJobFilledDetails: () => void,
    saveDraftJobApplyData: (data: {email: string, full_phone_number: string, resume: { url: string | File, name: string, size: number, selectId: string | number }[], cover_letter: { url: string | File, name: string, size: number, selectId: string | number }[]}) => void
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        padding: "0px 180px",
        textTransform: 'none',
        margin: "0px",
        "fontFamily": "Arial",
        display: "flex",
        justifyContent: "center",
        '@media (max-width: 600px)': {
            "padding": "0px 20px",
        }
    },
    tabs: {
        display: "flex",
        justifyContent: "space-between",
        "& .MuiTabs-flexContainer": {
            display: "flex",
            justifyContent: "space-between"
        },
        borderBottom: '1px solid #F4F4F4',
        width: "inherit"
    },
    tab: {
        flex: 1,
        textTransform: 'none',
        overflow: "hidden",
        justifyContent: "center",
        display: "flex",
    },
    selectedIndicator: {
        backgroundColor: "#6D97C1",
    },
    tabText: {
        color: "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px"
    },
    selectedTabText: {
        color: "#17365D",
        "fontFamily": "Arial",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "bold",
        lineHeight: "26px"
    },
    root2: {
        "gap":'50px',
        "alignItems": "baseline",
        "padding": "0px 48px 0px 110px",
        '@media (max-width: 600px)': {
            "padding": "40px"
        },
        '@media (max-width: 1200px)': {
            "padding": "0px 20px 0px 0px"
        }
    },
    header: {
        "color": "#9C9C9C",
        "fontFamily": "Arial",
        "fontSize": "16px",
        "fontStyle": "normal",
        "fontWeight": "lighter",
        "lineHeight": "22px",
        "paddingTop": "20px",
        "paddingBottom": "10px",
        "textAlign": "center"
    },
  
}));

export default function TabsComponent(Props: Props) {
    const { setSkillToShow, handleApplyJob, getTitleOfApplyButton, getJobFilledDetails, saveDraftJobApplyData, handleNavigationToMessage, goToLoginPage, goToSignUpPage, jobFeedList, applyJob, handleShowMore, showMore,mainState, handleLikeAndDislikeJob, resumeRadioButtonSelected, openUserDetailsHandler, jobStatus, jobDetails, handleChange, tabIndex, handleSaveAndUnSaveJob, applyJobHandler, loadMoreFunction, submitJobDetailHandler, loader, progress, remainingTime } = Props
    const classes = useStyles();
    return (<>
        <Grid className={classes.root} container>
            <Tabs
                value={tabIndex}
                onChange={handleChange}
                className={classes.tabs}
                classes={{ indicator: classes.selectedIndicator }}
            >
                <Tab className={classes.tab} label="Job Feed" {...a11yProps(0)} classes={{ selected: classes.selectedTabText, root: classes.tabText }} />
                <Tab className={classes.tab} disabled={!mainState.isUserloogedIn} label="My Saved Jobs" {...a11yProps(1)} classes={{ selected: classes.selectedTabText, root: classes.tabText }} />
                <Tab className={classes.tab}  disabled={!mainState.isUserloogedIn} label="Applied Jobs" {...a11yProps(2)} classes={{ selected: classes.selectedTabText, root: classes.tabText }} />
            </Tabs>
        </Grid>
        <Grid container 
         className={classes.root2}
         >
            <Grid item sm={12} md={5} lg={5}
             >
                <Typography className={classes.header}>{jobFeedList.length > 0 ? "Jobs based on your activity on Work Centivo" : "No Jobs Found"}</Typography>
                {
                    jobFeedList.map((item: Job) => {
                        return (
                            <>
                                {
                                    <Link to={`/Catalogue/${item.id}`} onClick={() => openUserDetailsHandler(item.id)}>
                                        <JobFeedComponent jobFeedList={item} jobDeatilsId={jobDetails.id} />
                                    </Link>
                                }
                            </>
                        )
                    }
                    )
                }
                <Grid item sm={12} md={6} lg={6}>
                {
                    jobFeedList.length > 0 && mainState.totalAllJobsPages > mainState.currentPage &&  <Grid container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CustomButtonOne title="Load more jobs" type="rounded" applyJobHandler={loadMoreFunction} />
                    </Grid>
                }
                </Grid>
               
            </Grid>
            {
                jobDetails && jobFeedList.length > 0 && Object.keys(jobDetails).length > 0&& <JobDetailsCard setSkillToShow={setSkillToShow} handleApplyJob={handleApplyJob} getTitleOfApplyButton={getTitleOfApplyButton}  saveDraftJobApplyData={saveDraftJobApplyData} handleNavigationToMessage={handleNavigationToMessage} goToLoginPage={goToLoginPage} goToSignUpPage={goToSignUpPage} loader={loader} progress={progress} remainingTime={remainingTime} mainState={ mainState} handleShowMore={handleShowMore} handleLikeAndDislikeJob={handleLikeAndDislikeJob} showMore={showMore} resumeRadioButtonSelected={resumeRadioButtonSelected} jobStatus={jobStatus || false} getJobDetails={jobDetails} handleSaveAndUnSaveJob={handleSaveAndUnSaveJob} getJobFilledDetails={getJobFilledDetails} applyJobHandler={applyJobHandler} applyJob={applyJob} submitJobDetailHandler={submitJobDetailHandler ? (data: FormValues) => submitJobDetailHandler(data) : (data: FormValues) => { }} />
            }
        </Grid>
    </>
    );
}
